<div class="register-background">
  @switch(registerStore.step()) { @case('sku') {
  <app-sku-selection />
  } @case('customer') {
  <app-data-input />
  } @case('authenticate') {
  <app-authenticate />
  } @case('confirmation') {
  <app-check-data />
  } @case ('payment') {
  <app-payment />
  } @case('done') {
  <app-registration-done />
  } }
  <app-progress-display />
  <div class="language-selection">
    <app-language-switch />
    <div class="flex-row-start-center gap-10">
    <a class="link" routerLink="/privacy" routerLinkActive="active">
      {{ "userMenu.privacy" | translate }}
    </a>
    <a class="link" routerLink="/imprint" routerLinkActive="active">
      {{ "userMenu.imprint" | translate }}
    </a>
  </div>
  </div>
</div>
