import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { CurrentUserComponent } from "../../auth/current-user/current-user.component";
import { LogoComponent } from "../../shared/logo/logo.component";
import { MobileWarningComponent } from "../../shared/mobile-warning/mobile-warning.component";
import { VersionDisplayComponent } from "../../shared/version-display/version-display.component";
import { UserMenuComponent } from "../user-menu/user-menu.component";
import { UserMenuStore } from './user-menu.store';

@Component({
    selector: 'app-user',
    imports: [RouterModule, UserMenuComponent, CurrentUserComponent, LogoComponent, FontAwesomeModule, VersionDisplayComponent, MobileWarningComponent],
    templateUrl: './user.component.html',
    styleUrl: './user.component.scss'
})
export class UserComponent {
  userMenuStore = inject(UserMenuStore);
  faBars = faBars;

  toggleCollapsed() {
    this.userMenuStore.toggleCollapsed();
  }
}
