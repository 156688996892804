<div class="progress">
  <progress [max]="registerStore.testVersionSelected() ? 4 : 5" value="{{ registerStore.stepIndex() }}"></progress>
  <div class="chip-container">
    <div
      class="progress-chip"
      [class.completed]="registerStore.stepIndex() > 0"
      [class.active]="registerStore.stepIndex() === 0"
    >
      <fa-icon size="2x" [icon]="faCubes"></fa-icon>
    </div>
    <div
      class="progress-chip"
      [class.completed]="registerStore.stepIndex() > 1"
      [class.active]="registerStore.stepIndex() === 1"
    >
      <fa-icon size="2x" [icon]="faUserTie"></fa-icon>
    </div>

    <div
      class="progress-chip"
      [class.completed]="registerStore.stepIndex() > 2"
      [class.active]="registerStore.stepIndex() === 2"
    >
      <fa-icon size="2x" [icon]="faBuildingLock"></fa-icon>
    </div>

    <div
      class="progress-chip"
      [class.completed]="registerStore.stepIndex() > 3"
      [class.active]="registerStore.stepIndex() === 3"
    >
      <fa-icon size="2x" [icon]="faFileCircleCheck"></fa-icon>
    </div>
    @if (!registerStore.testVersionSelected()) {
      <div
      class="progress-chip"
      [class.completed]="registerStore.stepIndex() > 4"
      [class.active]="registerStore.stepIndex() === 4"
    >
      <fa-icon size="2x" [icon]="faEuroSign"></fa-icon>
    </div>
    }
    <div
      class="progress-chip"
      [class.completed]="registerStore.stepIndex() === 5"
      [class.active]="registerStore.stepIndex() === 5"
    >
      <fa-icon size="2x" [icon]="faCheckCircle"></fa-icon>
    </div>
  </div>
</div>
