import { HttpClient } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { LanguageSwitchComponent } from '../../shared/language-switch/language-switch.component';
import { AuthService } from '../auth.service';
import { CurrentUserStore } from './current-user.store';

@Component({
    selector: 'app-current-user',
    imports: [FontAwesomeModule, TranslateModule, LanguageSwitchComponent],
    templateUrl: './current-user.component.html',
    styleUrl: './current-user.component.scss'
})
export class CurrentUserComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  currentUserStore = inject(CurrentUserStore);
  faUser = faUser;

  constructor(
    private readonly authService: AuthService,
    private readonly httpService: HttpClient
  ) {}

  ngOnInit(): void {
    this.getProfilePicture();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getProfilePicture() {
    // TODO: Generic profile pic handler for other IDPs
    this.httpService
      .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        responseType: 'blob',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profilePicture) => {
          const objectUrl = URL.createObjectURL(profilePicture);
          this.currentUserStore.setProfilePicture(objectUrl);
        },
        error: (error) => {
          this.currentUserStore.setProfilePicture(undefined);
        },
      });
  }

  logout() {
    this.authService.logout();
  }
}
