import { Component, HostListener, OnInit, signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faRotate,
  faTabletScreenButton,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-mobile-warning',
  imports: [DialogComponent, TranslateModule, FontAwesomeModule],
  templateUrl: './mobile-warning.component.html',
  styleUrl: './mobile-warning.component.scss',
})
export class MobileWarningComponent implements OnInit {
  windowWidth = signal<number>(window.innerWidth);
  windowHeight = signal<number>(window.innerHeight);

  mobileWarningVisible = signal<boolean>(false);
  overrideMobileWarning = signal<boolean>(false);

  faMobile = faTabletScreenButton;
  faRotate = faRotate;

  ngOnInit(): void {
    this.onWindowResize();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.windowWidth.set(window.innerWidth);
    this.windowHeight.set(window.innerHeight);

    if (!this.overrideMobileWarning()) {
      this.toggleMobileWarning();
    }
  }

  toggleMobileWarning() {
    if (this.windowWidth() < this.windowHeight()) {
      this.mobileWarningVisible.set(true);
    } else {
      this.mobileWarningVisible.set(false);
    }
  }

  ignore() {
    this.mobileWarningVisible.set(false);
    this.overrideMobileWarning.set(true);
  }
}
