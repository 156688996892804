<div class="btn-group">
  <button class="btn-success" (click)="onNewPriority()">
    {{ "projects.cards.addDiagramButton" | translate }}
  </button>
  <app-delete-project [projectId]="projectId()" (deleted)="onDeleted()" />
</div>
@if (!loading()) {
<div class="card-container">
  @for (diagram of diagrams(); track diagram.id) {
  <div class="card" (click)="onCardClicked(diagram.id)">
    <div class="card__header">
      <h3>{{ diagram.name }}</h3>
      <div class="flex-row-center-center gap-10">
        <span class="owner"
          ><fa-icon [icon]="faUser"></fa-icon> {{ diagram.ownerName }}</span
        >
        @if (diagram.archived) {
        <span>
          <fa-icon
            class="archived"
            [icon]="faFileZipper"
            [title]="'diagrams.archived' | translate"
          ></fa-icon>
        </span>
        } @if (diagram.isPrivate) {
        <span>
          <fa-icon class="private" [icon]="faLock"></fa-icon>
        </span>
        }
      </div>
    </div>
    <app-card-preview [diagram]="diagram"></app-card-preview>
  </div>
  }
</div>
} @else {
<div class="center" style="height: 400px">
  <app-loading-spinner></app-loading-spinner>
</div>
}
<app-new-diagram
  [showDialog]="showPriorityAddDialog()"
  [projectId]="projectId()"
  [privateOnly]="projectId() === 'private'"
  (priorityMatrixCreated)="onPriorityCreated($event)"
  (canceled)="onPriorityCanceled()"
  (loading)="onPriorityLoading($event)"
/>

