import { Component, EventEmitter, OnInit, Output, input, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { FormField } from '../../shared/form/form-field';
import { FormComponent } from "../../shared/form/form.component";
import { ProjectRepository } from '../project.repository';

@Component({
    selector: 'app-add-project',
    imports: [DialogComponent, TranslateModule, FormComponent],
    templateUrl: './add-project.component.html',
    styleUrl: './add-project.component.scss'
})
export class AddProjectComponent implements OnInit {
  destroy$ = new Subject<void>();
  fields = signal<FormField[]>([
    {
      name: 'projectName',
      fieldType: 'text',
      required: true,
      validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100)],
      labelKey: 'projects.add.name'
    },
    {
      name: 'isPrivate',
      fieldType: 'checkbox',
      required: false,
      labelKey: 'projects.add.private',
      defaultValue: false,
      validators: []
    }
  ]);

  readonly showDialog = input(false);
  readonly workgroupId = input('');
  readonly private = input(false);

  @Output() canceled = new EventEmitter<void>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() projectAdded = new EventEmitter<void>();

  constructor(private readonly projectRepository: ProjectRepository) {}

  ngOnInit(): void {
      if (this.private()) {
        this.fields.set([
          {
            name: 'projectName',
            fieldType: 'text',
            required: true,
            validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100)],
            labelKey: 'projects.add.name'
          },
        ]);
      }
  }

  addProject(form: FormGroup): void {
    this.loading.emit(true);
    if (this.private() || form.get('isPrivate')?.value) {
      this.addPrivateProject(form);
    } else if (this.workgroupId() === '') {
      this.addProjectWithoutWorkgroup(form);
    } else {
      this.addWorkgroupProject(form);
    }
  }

  private addPrivateProject(form: FormGroup): void {
    this.projectRepository
      .addPrivateProject({
        projectName: form.get('projectName')?.value
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading.emit(false);
        this.projectAdded.emit();
      });
  }

  private addWorkgroupProject(form: FormGroup): void {
    this.projectRepository
      .addProjectToWorkgroup({
        workgroupId: this.workgroupId(),
        projectName: form.get('projectName')?.value,
        isPrivate: false
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading.emit(false);
        this.projectAdded.emit();
      });
  }

  private addProjectWithoutWorkgroup(form: FormGroup): void {
    this.projectRepository
      .addProject({
        projectName: form.get('projectName')?.value,
        isPrivate: false,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading.emit(false);
        this.projectAdded.emit();
      });
  }

  cancel(): void {
    this.canceled.emit();
  }
}
