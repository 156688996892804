<div class="background">
  <div class="main-container">
    <div style="margin-bottom: 80px;">
      <app-logo [width]="'600px'" />
    </div>
    @if(!isLoggedIn()) {
    <div class="auth-button-group">
      <a class="login-button" (click)="login()">{{
        "home.login" | translate
      }}</a>
      <a class="register-button" routerLink="/register">{{
        "home.register" | translate
      }}</a>
      <app-language-switch />
      <div class="flex-row-start-center gap-10">
      <a class="link" routerLink="/privacy" routerLinkActive="active">
        {{  "userMenu.privacy" | translate }}
      </a>
      <a class="link" routerLink="/imprint" routerLinkActive="active">
        {{ "userMenu.imprint" | translate }}
      </a>
    </div>
    </div>
    } @else {
    <span class="redirect-message">{{
      "home.redirect-message" | translate
    }}</span>
    }
  </div>
</div>
