import {
  withDevtools,
  withStorageSync,
} from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TestVersionModel } from '../../models/test-version.model';
import { FeatureStore } from '../../shared/features/feature.store';
import { HttpService } from '../../shared/http.service';
import { UserModel, UserState } from '../../user/user.model';

const apiConfig = environment.apiConfig;

export type CurrentUserState = {
  loggedIn: boolean;
  userName: string | undefined;
  role: string | undefined;
  id: string | undefined;
  userState: UserState | undefined;
  customerActive: boolean | undefined;
  isTestVersion: boolean | undefined;
  remainingTestVersionDays: number | undefined;
  profilePicture: string | undefined;
};

const initialState: CurrentUserState = {
  loggedIn: false,
  userName: undefined,
  role: undefined,
  id: undefined,
  userState: undefined,
  customerActive: undefined,
  isTestVersion: undefined,
  remainingTestVersionDays: undefined,
  profilePicture: undefined,
};

export const CurrentUserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withStorageSync('currentUser'),
  withComputed(({ role }) => ({
    isElevatedUser: computed(
      () => role() === 'CustomerAdmin' || role() === 'CustomerManager'
    ),
    isCustomerAdmin: computed(() => role() === 'CustomerAdmin'),
  })),
  withMethods(
    (
      store,
      httpService = inject(HttpService),
      featureStore = inject(FeatureStore)
    ) => ({
      loginAndSetUserInfo(): Observable<void> {
        httpService
          .get<UserModel>(apiConfig.url + `/auth/current-user`)
          .subscribe({
            next: (user) => {
              patchState(store, {
                loggedIn: true,
                userName: user.username,
                role: user.role,
                id: user.id,
                userState: user.userState,
                customerActive: user.customerActive,
              });
              httpService
                .get<TestVersionModel>(apiConfig.url + `/auth/test-version`)
                .subscribe({
                  next: (testVersion) => {
                    patchState(store, {
                      isTestVersion: testVersion.isTestVersion,
                      remainingTestVersionDays:
                        testVersion.remainingTestVersionDays,
                    });
                  },
                  error: () => {
                    patchState(store, {
                      isTestVersion: false,
                      remainingTestVersionDays: 0,
                    });
                  },
                });
              return of();
            },
          });
        return of();
      },
      getTestVersionInfo(): Observable<void> {
        return new Observable<void>((observer) => {
          httpService
            .get<TestVersionModel>(apiConfig.url + `/auth/test-version`)
            .subscribe({
              next: (testVersion) => {
                patchState(store, {
                  isTestVersion: testVersion.isTestVersion,
                  remainingTestVersionDays:
                    testVersion.remainingTestVersionDays,
                });
                observer.next();
                observer.complete();
              },
              error: () => {
                patchState(store, {
                  isTestVersion: false,
                  remainingTestVersionDays: 0,
                });
                observer.next();
                observer.complete();
              },
            });
        });
      },
      logout() {
        patchState(store, {
          loggedIn: false,
          userName: undefined,
          role: undefined,
          id: undefined,
          userState: undefined,
          customerActive: undefined,
          isTestVersion: undefined,
          remainingTestVersionDays: undefined,
          profilePicture: undefined,
        });
      },
      setProfilePicture(profilePicture: string | undefined) {
        patchState(store, {
          profilePicture,
        });
      },
    })
  ),
  withDevtools('currentUser')
);
