import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpService } from "../shared/http.service";
import { DowngradeInformation } from "./downgrade-information/downgrade-information.model";
import { ChangeSubscriptionParam } from "./tweak-subscription/change-subscription-param.model";

const apiUrl = environment.apiConfig.url;

@Injectable({ providedIn: 'root' })
export class ManageSubscriptionRepository {
  constructor(private readonly httpService: HttpService) {}

  cancelSubscription(): Observable<Date> {
    return this.httpService.get(`${apiUrl}/subscription/cancel-subscription`);
  }

  upgradeOrDowngradeSubscription(param: ChangeSubscriptionParam): Observable<void> {
    return this.httpService.post(`${apiUrl}/subscription/upgrade-or-downgrade`, param);
  }

  canChangeSubscription(customerId: string): Observable<boolean> {
    return this.httpService.get(`${apiUrl}/subscription/can-change/${customerId}`);
  }

  getDowngradeInformation(customerId: string): Observable<DowngradeInformation> {
    return this.httpService.get(`${apiUrl}/subscription/downgrade-information/${customerId}`);
  }
}
