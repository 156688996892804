<app-dialog class="warning-dialog" [showDialog]="mobileWarningVisible()">
  <div class="flex-column-center-center">
    <div class="flex-row-center-center gap-40">
      <fa-icon class="mobile-warning rotate" size="3x" [icon]="faRotate" />
      <fa-icon class="mobile-warning phone" size="5x" [icon]="faMobile" />
      <fa-icon class="mobile-warning rotate" size="3x" [icon]="faRotate" />
    </div>
    <p>{{ 'mobile-warning.hint' | translate }}</p>
    <button class="btn-warning" (click)="ignore()">{{ 'mobile-warning.ignore-button' | translate }}</button>
  </div>
</app-dialog>
