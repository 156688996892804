import { withDevtools, withStorageSync } from "@angular-architects/ngrx-toolkit";
import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { DowngradeInformation } from "./downgrade-information/downgrade-information.model";


export type ManageSubscriptionState = {
  subscriptionCanceled: boolean;
  periodEnd: Date | undefined;
  loading: boolean;
  showCancelDialog: boolean;
  customerId: string | undefined;
  canChangeSubscription: boolean;
  downgradeInformation: DowngradeInformation | undefined;
}

const initialState: ManageSubscriptionState = {
  subscriptionCanceled: false,
  periodEnd: undefined,
  loading: false,
  showCancelDialog: false,
  customerId: undefined,
  canChangeSubscription: false,
  downgradeInformation: undefined,
}

export const ManageSubscriptionStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ }) => ({})),
  withDevtools('ManageSubscriptionStore'),
  withStorageSync('ManageSubscriptionStore'),
  withMethods((store) => ({
    cancelSubscription: (date: Date) => patchState(store, { subscriptionCanceled: true, periodEnd: date }),
    setLoading: () => patchState(store, { loading: true }),
    stopLoading: () => patchState(store, { loading: false }),
    toggleCancelDialog: () => patchState(store, { showCancelDialog: !store.showCancelDialog() }),
    reset: () => patchState(store, initialState),
    setCustomerId: (customerId: string) => patchState(store, { customerId }),
    setCanChangeSubscription: (canChangeSubscription: boolean) => patchState(store, { canChangeSubscription }),
    setDowngradeInformation: (downgradeInformation: DowngradeInformation) => patchState(store, { downgradeInformation })
  })
));
