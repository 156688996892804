import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStore } from '../../auth/current-user/current-user.store';
import { MultiselectComponent } from '../../shared/multiselect/multiselect.component';
import { MultiselectStore } from '../../shared/multiselect/multiselect.store';
import { UserModel } from '../../user/user.model';
import { WorkgroupRepository } from '../workgroup.repository';

@Component({
    selector: 'app-add-user-workgroup',
    imports: [
        TranslateModule,
        FontAwesomeModule,
        FormsModule,
        CommonModule,
        MultiselectComponent,
    ],
    templateUrl: './add-user-workgroup.component.html',
    styleUrl: './add-user-workgroup.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddUserWorkgroupComponent implements OnInit, OnDestroy {
  multiselectStore = inject(MultiselectStore);
  currentUserStore = inject(CurrentUserStore);

  @Input({ required: true }) workgroupId!: string;
  faSearch = faSearch;
  faUser = faUser;
  destroy$ = new Subject<void>();
  buttonDisabled = signal<boolean>(true);

  alreadyAddedUsers = signal<UserModel[] | undefined>(undefined);
  users = signal<UserModel[] | undefined>(undefined);

  searchTerm?: string;

  constructor(private readonly workgroupRepository: WorkgroupRepository) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.load();
  }

  private load() {
    this.workgroupRepository
      .getCustomerUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        this.users.set(this.assignCorrectStatus(users));
      });
    this.workgroupRepository
      .getWorkgroupUsers(this.workgroupId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        this.alreadyAddedUsers.set(this.assignCorrectStatus(users));
      });
  }

  private assignCorrectStatus(users: UserModel[]): any[] {
    return users.map((user) => ({
      ...user,
      locked: user.id === this.currentUserStore.id(),
    }));
  }

  onUsersChanged(changedUsers: any[]): void {
    const arraysDiffer =
      JSON.stringify(changedUsers) !== JSON.stringify(this.alreadyAddedUsers());
    this.buttonDisabled.set(!arraysDiffer);
  }

  onSave(): void {
    this.workgroupRepository
      .updateWorkgroupUsers(this.workgroupId, {
        userIds: this.multiselectStore
          .selectedItems()
          .map((user: UserModel) => user.id),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.users.set(undefined);
          this.alreadyAddedUsers.set(undefined);
          this.load();
          this.buttonDisabled.set(true);
        },
        error: (error) => console.log(error),
      });
  }
}
