import { Component, inject, input, output, signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CurrentUserStore } from '../../auth/current-user/current-user.store';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { FeatureStore } from '../../shared/features/feature.store';
import { ProjectRepository } from '../project.repository';

@Component({
    selector: 'app-delete-project',
    imports: [ConfirmationDialogComponent, FontAwesomeModule],
    templateUrl: './delete-project.component.html',
    styleUrl: './delete-project.component.scss'
})
export class DeleteProjectComponent {
  faTrash = faTrash;

  projectId = input<string | undefined>(undefined);
  deleted = output<void>();

  currentUserStore = inject(CurrentUserStore);
  featureStore = inject(FeatureStore);
  projectRepository = inject(ProjectRepository);

  showProjectDeleteDialog = signal<boolean>(false);

  onDelete() {
    this.showProjectDeleteDialog.set(true);
  }

  onDeleteCanceled() {
    this.showProjectDeleteDialog.set(false);
  }

  deleteProject() {
    if (this.projectId()) {
      this.projectRepository.deleteProject(this.projectId()!).subscribe({
        next: () => {
          this.showProjectDeleteDialog.set(false);
          this.deleted.emit();
        }
      });
    }
  }
}
