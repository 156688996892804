{
  "name": "flowstruct-spa",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --proxy-config proxy.conf.json",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular-architects/ngrx-toolkit": "^18.1.1",
    "@angular/animations": "^19.0.5",
    "@angular/cdk": "^18.2.6",
    "@angular/common": "^19.0.5",
    "@angular/compiler": "^19.0.5",
    "@angular/core": "^19.0.5",
    "@angular/forms": "^19.0.5",
    "@angular/platform-browser": "^19.0.5",
    "@angular/platform-browser-dynamic": "^19.0.5",
    "@angular/router": "^19.0.5",
    "@azure/msal-angular": "^3.0.23",
    "@azure/msal-browser": "^3.22.0",
    "@azure/msal-common": "^14.14.2",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@fortawesome/free-brands-svg-icons": "^6.5.2",
    "@fortawesome/free-regular-svg-icons": "^6.5.2",
    "@fortawesome/free-solid-svg-icons": "^6.5.2",
    "@ngrx/signals": "^19.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "ngx-matomo-client": "^6.3.1",
    "rxjs": "~7.8.1",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular/cli": "^19.0.6",
    "@angular/compiler-cli": "^19.0.5",
    "@types/jasmine": "~5.1.0",
    "@types/uuid": "^10.0.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.5.2"
  },
  "overrides": {
    "ngx-matomo-client": {
      "@angular/common@": ">=19.0.5",
      "@angular/core@": ">=19.0.5"
    },
    "@angular-architects/ngrx-toolkit": {
      "@ngrx/signals@": ">=19.0.0"
    }
  }
}
