import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { FormField } from '../../../shared/form/form-field';
import { FormComponent } from '../../../shared/form/form.component';
import { LanguageSwitchComponent } from "../../../shared/language-switch/language-switch.component";
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { LogoComponent } from "../../../shared/logo/logo.component";
import { RegisterStore } from '../register.store';
import { PaymentCanceledRepository } from './payment-canceled.repository';

@Component({
    selector: 'app-payment-canceled',
    imports: [
        TranslateModule,
        RouterModule,
        FormComponent,
        LoadingSpinnerComponent,
        LogoComponent,
        LanguageSwitchComponent
    ],
    templateUrl: './payment-canceled.component.html',
    styleUrl: './payment-canceled.component.scss'
})
export class PaymentCanceledComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  loading = signal<boolean>(false);
  showThankYou = signal<boolean>(false);
  origin = signal<string | undefined>(undefined);

  paymentCanceledRepository = inject(PaymentCanceledRepository);
  router = inject(Router);
  registerStore = inject(RegisterStore);
  activatedRoute = inject(ActivatedRoute);

  formFields: FormField[] = [
    {
      name: 'customer-name',
      fieldType: 'text',
      labelKey:
        'unauthenticated.register.payment-canceled.form.customer-name.label',
      required: false,
    },
    {
      name: 'reason',
      fieldType: 'radio',
      labelKey: 'unauthenticated.register.payment-canceled.form.reason.label',
      selectOptions: [
        {
          key: 'NotInterested',
          translateKey:
            'unauthenticated.register.payment-canceled.form.reason.not-interested',
          value: 'NotInterested',
        },
        {
          key: 'TooExpensive',
          translateKey:
            'unauthenticated.register.payment-canceled.form.reason.too-expensive',
          value: 'TooExpensive',
        },
        {
          key: 'PaymentMethodUnavailable',
          translateKey:
            'unauthenticated.register.payment-canceled.form.reason.payment-method-unavailable',
          value: 'PaymentMethodUnavailable',
        },
        {
          key: 'Other',
          translateKey:
            'unauthenticated.register.payment-canceled.form.reason.other',
          value: 'Other',
        },
      ],
      required: true,
    },
    {
      name: 'feedback',
      fieldType: 'textarea',
      labelKey: 'unauthenticated.register.payment-canceled.form.feedback.label',
      required: true,
    },
  ];

  onSubmitFeedback($event: FormGroup) {
    this.loading.set(true);
    this.paymentCanceledRepository
      .provideFeedback(
        $event.value.reason,
        $event.value.feedback,
        $event.get('customer-name')?.value
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.loading.set(false);
          this.showThankYou.set(true);
        },
        error: () => {
          this.loading.set(false);
        },
      });
  }

  ngOnInit(): void {
      const origin = this.activatedRoute.snapshot.queryParams['origin'];
      if (origin === 'register') {
        this.origin.set('/register');
      } else {
        this.origin.set('/renew-subscription');
      }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onBack() {
    this.registerStore.reset();
    this.router.navigate(['/home']);
  }
}
