@if (kanbanModel()) { @if (!diagram()?.archived) {
<div class="btn-group">
  @if (kanbanModel()?.lanes!.length < maxLaneCount) {
  <button class="btn-success" (click)="addLane()">
    {{ "diagrams.kanban.add-lane" | translate }}
  </button>
  } @if (kanbanModel()?.lanes!.length > 0) {
  <button class="btn-primary" (click)="addCard()">
    <fa-icon size="1x" [icon]="faPlus"></fa-icon>
    {{ "diagrams.kanban.add-card" | translate }}
  </button>
  }
</div>
}
<div
  class="lane-container"
  cdkDropListGroup
  cdkDropList
  cdkDropListOrientation="horizontal"
  [cdkDropListData]="kanbanModel()!.lanes"
  (cdkDropListDropped)="dropLane($event)"
>
  @for(lane of kanbanModel()!.lanes; track lane.id; let i = $index) {
  <div
    class="lane"
    cdkDropList
    cdkDrag
    [cdkDragDisabled]="diagram()?.archived"
    [cdkDropListData]="lane.cards"
    (cdkDropListDropped)="drop($event)"
  >
    <section class="lane__header">
      <h2>{{ lane.title }}</h2>
      <div>
        @if (!diagram()?.archived) {
        <fa-icon
          id="delete-lane"
          [icon]="faTrash"
          (click)="deleteLane(i)"
        ></fa-icon>
        <fa-icon id="move" [icon]="faMove" cdkDragHandle></fa-icon>
        }
      </div>
    </section>
    <div class="cards">
      @for(card of lane.cards; track card.id) {
      <div class="card" cdkDrag [cdkDragData]="i" [cdkDragDisabled]="diagram()?.archived">
        <div class="card__header">
          <h3>{{ card.title }}</h3>
          @if (!diagram()?.archived) {
          <fa-icon
            (click)="deleteCard(lane.id, card.id)"
            id="trashCan"
            [icon]="faTrash"
            size="1x"
            color="$bgDanger"
          ></fa-icon>
          }
        </div>

        <div class="card__content">
          <textarea class="readonly-textarea" readonly>{{
            card.description
          }}</textarea>
        </div>
      </div>
      }
    </div>
  </div>
  }
</div>
<app-add-card
  [showDialog]="showAddCard()"
  (todoAdded)="onSubmitCard($event)"
  (canceled)="onCancelCard()"
/>
<app-add-lane
  [showDialog]="showAddLane()"
  (added)="onSubmitLane($event)"
  (canceled)="onCancelLane()"
/>
}
