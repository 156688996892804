<div class="user-layout">
  <nav class="user-layout__menu" [class.collapsed]="userMenuStore.collapsed()">
    <div class="user-layout__menu__header">
      <button class="collapse-btn" (click)="toggleCollapsed()" [class.collapsed]="userMenuStore.collapsed()">
        @if (userMenuStore.collapsed()) {
          <img src="images/flowstruct-logo.png" width="26px" alt="Logo" />
        } @else {
          <fa-icon
          size="2x"
          class="header__icon"
          [icon]="faBars"
        />
        }

      </button>
      @if (!userMenuStore.collapsed()) {
        <div style="margin-left: 20px;">
      <app-logo [width]="'200px'" [inverted]="true"></app-logo>
    </div>
      }
    </div>
    <section class="user-layout__menu__navigation" [class.collapsed]="userMenuStore.collapsed()">
      <app-user-menu [menuCollapsed]="userMenuStore.collapsed()"></app-user-menu>
      <app-version-display></app-version-display>
    </section>
  </nav>
  <main class="user-layout__content" [class.collapsed]="userMenuStore.collapsed()">
    <div class="user-layout__content__inner">
      <app-current-user></app-current-user>
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<app-mobile-warning></app-mobile-warning>
