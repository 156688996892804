<div class="currentUser-container">
  @if (currentUserStore.loggedIn()) { @if (currentUserStore.isTestVersion()) {
  <span class="test-version">
    {{ "currentUser.test-version" | translate }}
    {{ currentUserStore.remainingTestVersionDays() }}
  </span>
  }
  <app-language-switch />
  <div class="flex-row-center-center gap-10">
    @if (currentUserStore.profilePicture()) {
    <img [src]="currentUserStore.profilePicture()" />
    } @else {
      <fa-icon [icon]="faUser" size="lg"></fa-icon>
    }
    <span
      >{{ currentUserStore.userName() }} ({{
        "currentUser." + currentUserStore.role!() | translate
      }})</span
    >
    <button class="btn-danger" (click)="logout()">
      {{ "currentUser.logout" | translate }}
    </button>
  </div>
  }
</div>
