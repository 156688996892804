<div class="imprint-wrapper">
  <app-logo></app-logo>
  <div class="imprint-container">
    @if (languageStore.currentLanguage() === 'de') {
    <h1>Impressum</h1>

    <p>
      Marvin Binder<br />
      Marvin Binder Software &amp; Consulting<br />
      Hohe Stra&szlig;e 1<br />
      56477 Rennerod
    </p>

    <h2>Kontakt</h2>
    <p>
      Telefon: +49 152 26738048<br />
      E-Mail: info&#64;marvinbinder.com
    </p>

    <h2>Umsatzsteuer-ID</h2>
    <p>
      Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
      Umsatzsteuergesetz:<br />
      DE329479505
    </p>

    <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
    <h2>EU-Streitschlichtung</h2>
    <p>
      Die Europ&auml;ische Kommission stellt eine Plattform zur
      Online-Streitbeilegung (OS) bereit:
      <a
        href="https://ec.europa.eu/consumers/odr/"
        target="_blank"
        rel="noopener noreferrer"
        >https://ec.europa.eu/consumers/odr/</a
      >.<br />
      Unsere E-Mail-Adresse finden Sie oben im Impressum.
    </p>

    <h2>
      Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
    </h2>
    <p>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    } @else {
    <h1>Site Notice</h1>

    <p>
      Marvin Binder<br />
      Marvin Binder Software &amp; Consulting<br />
      Hohe Stra&szlig;e 1<br />
      56477 Rennerod
    </p>

    <h2>Contact</h2>
    <p>
      Phone: +49 152 26738048<br />
      E-mail: info&#64;marvinbinder.com
    </p>

    <h2>VAT ID</h2>
    <p>
      Sales tax identification number according to Sect. 27 a of the Sales Tax
      Law:<br />
      DE329479505
    </p>

    <h2>Information regarding professional liability insurance</h2>
    <h2>EU dispute resolution</h2>
    <p>
      The European Commission provides a platform for online dispute resolution
      (ODR):
      <a
        href="https://ec.europa.eu/consumers/odr/"
        target="_blank"
        rel="noopener noreferrer"
        >https://ec.europa.eu/consumers/odr/</a
      >.<br />
      Our e-mail address can be found above in the site notice.
    </p>

    <h2>
      Dispute resolution proceedings in front of a consumer arbitration board
    </h2>
    <p>
      We are not willing or obliged to participate in dispute resolution
      proceedings in front of a consumer arbitration board.
    </p>
    }
  </div>
</div>
