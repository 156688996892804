@if (currentUserStore.isCustomerAdmin() && featureStore.customerSku() > 0) {
  <button class="btn-danger" (click)="onDelete()">
    <fa-icon [icon]="faTrash"></fa-icon>
  </button>
  <app-confirmation-dialog
    [titleKey]="'projects.cards.delete-project'"
    [messageKey]="'projects.cards.delete-project-hint'"
    [confirmKey]="'projects.cards.confirm-delete-project'"
    (confirm)="deleteProject()"
    (cancel)="onDeleteCanceled()"
    [show]="showProjectDeleteDialog()"
  />
}

