import { DatePipe } from '@angular/common';
import { Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PriorityIssue } from '../priority-issue';
import { PriorityIssueState } from './priority-issue-state';

@Component({
    selector: 'app-priority-issue',
    imports: [FontAwesomeModule, TranslateModule, DatePipe],
    templateUrl: './priority-issue.component.html',
    styleUrl: './priority-issue.component.scss'
})
export class PriorityIssueComponent implements OnInit {
  faTrash = faTrash;
  faCheck = faCheck;
  @Input({required: true}) issue!: PriorityIssue;
  @Input() displayOnly = false;
  archived = input<boolean>(false);
  @Output() remove = new EventEmitter<void>();
  @Output() removeForever = new EventEmitter<void>();
  @Output() done = new EventEmitter<void>();
  protected priorityLetter!: string;
  protected historyState!: PriorityIssueState;

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.priorityLetter = this.getLetter();
    this.historyState = this.issue.state!;
  }

  getLetter(): string {
    return this.issue.priority.toString();
  }

  removeIssue(): void {
    if (!this.displayOnly) {
      this.translateService.get('diagrams.priority.remove-issue.text').subscribe((translation: string) => {
        if (confirm(translation)) {
          this.remove.emit();
        }
      });
    } else {
      this.translateService.get('diagrams.priority.remove-forever.text').subscribe((translation: string) => {
        if (confirm(translation)) {
          this.removeForever.emit();
        }
      });
    }
  }

  markAsDone(): void {
    this.done.emit();
  }
}
