import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, inject, input, OnDestroy, OnInit, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { UserMenuStore } from '../../user/user/user-menu.store';
import { DiagramModel } from '../models/diagram.model';
import { UpdateDiagramModel } from '../models/update-diagram.model';
import { AddPriorityIssueComponent } from './add-priority-issue/add-priority-issue.component';
import { NewPriorityIssue } from './add-priority-issue/new-priority-issue';
import { PriorityIssue } from './priority-issue';
import { PriorityIssueComponent } from './priority-issue/priority-issue.component';
import { Priority, PriorityHelper } from './priority.enum';
import { PriorityModel } from './priority.model';
import { PriorityService } from './priority.service';

@Component({
    selector: 'app-priority',
    imports: [
        TranslateModule,
        FontAwesomeModule,
        PriorityIssueComponent,
        CdkDrag,
        CdkDropList,
        AddPriorityIssueComponent,
        RouterModule
    ],
    templateUrl: './priority.component.html',
    styleUrl: './priority.component.scss'
})
export class PriorityComponent implements OnInit, OnDestroy {
  $destroy = new Subject<void>();
  userMenuStore = inject(UserMenuStore);

  diagram = input<DiagramModel | undefined>(undefined);
  changed = output<UpdateDiagramModel>();

  ngOnInit(): void {
    this.initializePriorityData();
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  faPlus = faPlus;
  faTrash = faTrash;

  draggedIssue = undefined;
  dialogPriority = Priority.None;
  dialogVisible = false;

  aIssues: PriorityIssue[] = [];
  bIssues: PriorityIssue[] = [];
  cIssues: PriorityIssue[] = [];
  dIssues: PriorityIssue[] = [];
  doneIssues: PriorityIssue[] = [];
  removedIssues: PriorityIssue[] = [];

  protected get doneOrRemovedIssues(): PriorityIssue[] {
    const combined = [...this.doneIssues, ...this.removedIssues];
    combined.sort((a, b) => {
      if (a.updated && b.updated) {
        const sorted =
          new Date(b.updated)?.getTime() ??
          0 - new Date(a.updated)?.getTime() ??
          0;
        if (sorted > 0) {
          return -1;
        } else if (sorted < 0) {
          return 1;
        } else {
          return 0;
        }
      }
      return 0;
    });
    return combined;
  }

  constructor(
    private readonly priorityService: PriorityService
  ) {}

  initializePriorityData(): void {
        const data = this.priorityService.deserializePriorityData(
          this.diagram()!.diagramJson
        );
        this.aIssues = data.aIssues ?? [];
        this.bIssues = data.bIssues ?? [];
        this.cIssues = data.cIssues ?? [];
        this.dIssues = data.dIssues ?? [];
        this.doneIssues = data.doneIssues ?? [];
        this.removedIssues = data.removedIssues ?? [];
  }

  drop(event: CdkDragDrop<PriorityIssue[]>, zone: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray<PriorityIssue>(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    const foundIssue = event.container.data[event.currentIndex];

    switch (zone) {
      case 'A':
        foundIssue!.priority = Priority.A;
        break;
      case 'B':
        foundIssue!.priority = Priority.B;
        break;
      case 'C':
        foundIssue!.priority = Priority.C;
        break;
      case 'D':
        foundIssue!.priority = Priority.D;
        break;
      case 'None':
        foundIssue!.priority = Priority.None;
        break;
    }
    this.updatePriority();
  }

  showDialog(priority: string): void {
    this.dialogVisible = true;
    this.dialogPriority = PriorityHelper.getPriorityFromString(priority);
  }

  onDialogCanceled(): void {
    this.dialogVisible = false;
  }

  onAddedPriority(issue: NewPriorityIssue) {
    const createdIssue: PriorityIssue = {
      id: uuid(),
      title: issue.title,
      description: issue.description!,
      priority: issue.priority,
      updated: new Date(),
      state: 'active',
    };
    switch (issue.priority) {
      case Priority.A:
        this.aIssues.push(createdIssue);
        break;
      case Priority.B:
        this.bIssues.push(createdIssue);
        break;
      case Priority.C:
        this.cIssues.push(createdIssue);
        break;
      case Priority.D:
        this.dIssues.push(createdIssue);
        break;
      default:
        break;
    }
    this.dialogVisible = false;

    this.updatePriority();
  }

  updatePriority() {
    const data: PriorityModel = {
      aIssues: this.aIssues,
      bIssues: this.bIssues,
      cIssues: this.cIssues,
      dIssues: this.dIssues,
      doneIssues: this.doneIssues,
      removedIssues: this.removedIssues,
      type: 'priority',
    };
    const updateParam: UpdateDiagramModel = {
      id: this.diagram()?.id!,
      name: this.diagram()?.name!,
      diagramJson: this.priorityService.serializePriorityData(data),
    };
    this.changed.emit(updateParam);
  }

  removeIssue(issue: PriorityIssue, originZone: string) {
    switch (originZone) {
      case 'A':
        this.aIssues = this.aIssues.filter((x) => x.id !== issue.id);
        break;
      case 'B':
        this.bIssues = this.bIssues.filter((x) => x.id !== issue.id);
        break;
      case 'C':
        this.cIssues = this.cIssues.filter((x) => x.id !== issue.id);
        break;
      case 'D':
        this.dIssues = this.dIssues.filter((x) => x.id !== issue.id);
        break;
    }

    issue.updated = new Date();
    issue.state = 'removed';
    this.removedIssues.push(issue);
    this.updatePriority();
  }

  removeIssueForever(issue: PriorityIssue) {
    this.removedIssues = this.removedIssues.filter((x) => x.id !== issue.id);
    this.doneIssues = this.doneIssues.filter((x) => x.id !== issue.id);
    this.updatePriority();
  }

  markAsDone(issue: PriorityIssue) {
    issue.updated = new Date();
    switch (issue.priority) {
      case Priority.A:
        this.aIssues = this.aIssues.filter((x) => x.id !== issue.id);
        break;
      case Priority.B:
        this.bIssues = this.bIssues.filter((x) => x.id !== issue.id);
        break;
      case Priority.C:
        this.cIssues = this.cIssues.filter((x) => x.id !== issue.id);
        break;
      case Priority.D:
        this.dIssues = this.dIssues.filter((x) => x.id !== issue.id);
        break;
      case Priority.None:
        break;
    }

    issue.state = 'done';
    this.doneIssues.push(issue);
    this.updatePriority();
  }
}
