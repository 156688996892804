<div class="diagram-wrapper">
  <div class="diagram-container">
    <div class="diagram-container__diagram">
      <section
        cdkDropList
        #bZone="cdkDropList"
        [cdkDropListConnectedTo]="[aZone, cZone, dZone, bZone]"
        id="zone-b"
        class="priority-zone"
        (cdkDropListDropped)="drop($event, 'B')"
        [cdkDropListData]="bIssues"
      >
        <p class="priority-letter">B</p>
        <p class="priority-legend">
          {{ "diagrams.priority.legend.b" | translate }}
        </p>
        @if (!diagram()?.archived) {
        <button class="btn-success add-issue-button" (click)="showDialog('B')">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        }
        <div class="priority-zone__issue-container">
          @for (issue of bIssues; track issue.id) {
          <app-priority-issue
            [issue]="issue"
            cdkDrag
            [cdkDragData]="issue"
            [cdkDragDisabled]="diagram()?.archived"
            [archived]="diagram()!.archived"
            (remove)="removeIssue(issue, 'B')"
            (done)="markAsDone(issue)"
          />
          }
        </div>
      </section>
      <section
        cdkDropList
        #aZone="cdkDropList"
        [cdkDropListConnectedTo]="[bZone, cZone, dZone]"
        id="zone-a"
        class="priority-zone"
        (cdkDropListDropped)="drop($event, 'A')"
        [cdkDropListData]="aIssues"
      >
        <p class="priority-letter">A</p>
        <p class="priority-legend">
          {{ "diagrams.priority.legend.a" | translate }}
        </p>
        @if (!diagram()?.archived) {
        <button class="btn-success add-issue-button" (click)="showDialog('A')">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        }
        <div class="priority-zone__issue-container">
          @for (issue of aIssues; track issue.id) {
          <app-priority-issue
            [issue]="issue"
            cdkDrag
            [cdkDragData]="issue"
            [cdkDragDisabled]="diagram()?.archived"
            [archived]="diagram()!.archived"
            (remove)="removeIssue(issue, 'A')"
            (done)="markAsDone(issue)"
          />
          }
        </div>
      </section>
      <section
        cdkDropList
        #dZone="cdkDropList"
        [cdkDropListConnectedTo]="[aZone, bZone, cZone]"
        id="zone-d"
        class="priority-zone"
        (cdkDropListDropped)="drop($event, 'D')"
        [cdkDropListData]="dIssues"
      >
        <p class="priority-letter">D</p>
        <p class="priority-legend">
          {{ "diagrams.priority.legend.d" | translate }}
        </p>
        @if (!diagram()?.archived) {
        <button class="btn-success add-issue-button" (click)="showDialog('D')">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        }
        <div class="priority-zone__issue-container">
          @for (issue of dIssues; track issue.id) {
          <app-priority-issue
            [issue]="issue"
            cdkDrag
            [cdkDragData]="issue"
            [cdkDragDisabled]="diagram()?.archived"
            [archived]="diagram()!.archived"
            (remove)="removeIssue(issue, 'D')"
            (done)="markAsDone(issue)"
          />
          }
        </div>
      </section>
      <section
        cdkDropList
        #cZone="cdkDropList"
        [cdkDropListConnectedTo]="[aZone, bZone, dZone]"
        id="zone-c"
        class="priority-zone"
        (cdkDropListDropped)="drop($event, 'C')"
        [cdkDropListData]="cIssues"
      >
        <p class="priority-letter">C</p>
        <p class="priority-legend">
          {{ "diagrams.priority.legend.c" | translate }}
        </p>
        @if (!diagram()?.archived) {
        <button class="btn-success add-issue-button" (click)="showDialog('C')">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        }
        <div class="priority-zone__issue-container">
          @for (issue of cIssues; track issue.id) {
          <app-priority-issue
            [issue]="issue"
            cdkDrag
            [cdkDragData]="issue"
            [cdkDragDisabled]="diagram()?.archived"
            [archived]="diagram()!.archived"
            (remove)="removeIssue(issue, 'C')"
            (done)="markAsDone(issue)"
          />
          }
        </div>
      </section>
    </div>
    <aside class="diagram-history">
      <div class="diagram-history-title">
        <h2 class="diagram-history-title__h2">
          {{ "diagrams.priority.history.title" | translate }}
        </h2>
      </div>
      <div class="diagram-history-container">
        <div class="diagram-history-container__issues">
          @for (issue of doneOrRemovedIssues; track issue.id) {
          <app-priority-issue
            [issue]="issue"
            [displayOnly]="true"
            [archived]="diagram()!.archived"
            (removeForever)="removeIssueForever(issue)"
          />
          }
        </div>
      </div>
    </aside>
  </div>
</div>
<app-add-priority-issue
  [showDialog]="dialogVisible"
  [forPriority]="dialogPriority"
  (canceled)="onDialogCanceled()"
  (addedPriority)="onAddedPriority($event)"
/>

<footer
  class="zone-neutral-container"
  [class.collapsed]="userMenuStore.collapsed()"
></footer>
