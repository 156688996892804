import { Component, inject, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentUserStore } from '../../auth/current-user/current-user.store';
import { Sku } from '../../models/enums/sku.enum';

@Component({
    selector: 'app-test-version-legend',
    imports: [TranslateModule],
    templateUrl: './test-version-legend.component.html',
    styleUrl: './test-version-legend.component.scss'
})
export class TestVersionLegendComponent {
  sku = input<Sku | undefined>(undefined);
  height = input<number | undefined>(25);
  isDiagramButton = input<boolean>(false);
  currentUserStore = inject(CurrentUserStore);
}
