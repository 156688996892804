import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStore } from '../../auth/current-user/current-user.store';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { DiagramRepository } from '../diagram.repository';
import { KanbanComponent } from '../kanban/kanban.component';
import { LastEditedComponent } from '../last-edited/last-edited.component';
import { LeanCanvasComponent } from '../lean-canvas/lean-canvas.component';
import { DiagramModel } from '../models/diagram.model';
import { UpdateDiagramModel } from '../models/update-diagram.model';
import { PriorityComponent } from '../priority/priority.component';
import { RiskMatrixComponent } from '../risk-matrix/risk-matrix.component';

@Component({
    selector: 'app-diagram-viewer',
    imports: [
        PriorityComponent,
        LeanCanvasComponent,
        KanbanComponent,
        RiskMatrixComponent,
        FontAwesomeModule,
        TranslateModule,
        LastEditedComponent,
        LoadingSpinnerComponent,
        DialogComponent
    ],
    templateUrl: './diagram-viewer.component.html',
    styleUrl: './diagram-viewer.component.scss'
})
export class DiagramViewerComponent implements OnInit {
  destroy$ = new Subject<void>();
  diagramId = signal<string>('');
  diagram = signal<DiagramModel | undefined>(undefined);
  diagramType = signal<string | undefined>(undefined);
  loading = signal<boolean>(false);
  showArchiveDialog = signal<boolean>(false);
  showDeleteDialog = signal<boolean>(false);

  private readonly diagramRepository = inject(DiagramRepository);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  readonly currentUserStore = inject(CurrentUserStore);

  faArrowLeft = faArrowLeft;

  ngOnInit(): void {
    this.diagramId.set(this.activatedRoute.snapshot.params['id']);
    this.loadDiagram();
  }

  loadDiagram(): void {
    this.loading.set(true);
    this.diagramRepository
      .get(this.diagramId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((diagram) => {
        const diagramData = JSON.parse(diagram.diagramJson);
        this.diagramType.set(diagramData.type);
        this.diagram.set(diagram);
        this.loading.set(false);
      });
  }

  onBack() {
    if (!this.diagram()?.projectId !== null) {
      this.router.navigate([
        'user/dashboard/',
        this.diagram()?.projectId ?? '',
      ]);
    } else {
      this.router.navigate(['user/dashboard/private']);
    }
  }

  onDiagramChanged(model: UpdateDiagramModel) {
    if (this.diagram()?.archived) {
      return;
    }
    this.loading.set(true);
    this.diagramRepository.update(model).subscribe({
      next: () => {
        this.loadDiagram();
        this.loading.set(true);
      },
    });
  }

  onArchiveRequest() {
    this.showArchiveDialog.set(true);
  }

  cancelArchivation() {
    this.showArchiveDialog.set(false);
  }

  archiveDiagram() {
    this.loading.set(true);
    this.diagramRepository.archive(this.diagramId()).subscribe({
      next: () => {
        this.loading.set(false);
        this.showArchiveDialog.set(false);
        this.loadDiagram();
      },
      error: () => {
        this.loading.set(false);
        this.showArchiveDialog.set(false);
      }
    });
  }

  onDeleteRequest() {
    this.showDeleteDialog.set(true);
  }

  cancelDelete() {
    this.showDeleteDialog.set(false);
  }

  deleteDiagram() {
    this.loading.set(true);
    this.diagramRepository.delete(this.diagramId()).subscribe({
      next: () => {
        this.loading.set(false);
        this.showDeleteDialog.set(false);
        this.router.navigate(['user/dashboard/private']);
      },
      error: () => {
        this.loading.set(false);
        this.showDeleteDialog.set(false);
      }
    });
  }
}
