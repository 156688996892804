import { withDevtools, withStorageSync } from "@angular-architects/ngrx-toolkit";
import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";

export type LanguageSwitchState = {
  currentLanguage: string | undefined;
  showDropdown: boolean;
}

const initialState: LanguageSwitchState = {
  currentLanguage: undefined,
  showDropdown: false
}

export const LanguageSwitchStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ }) => ({})),
  withDevtools('LanguageSwitchStore'),
  withStorageSync('LanguageSwitchStore'),
  withMethods((store) => ({
    setLanguage: (language: string) => patchState(store, { currentLanguage: language }),
    toggleDropdown: () => patchState(store, { showDropdown: !store.showDropdown() })
  })
));
