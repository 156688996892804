<h1>{{ "manage-subscription.title" | translate }}</h1>
@if (!currentUserStore.isTestVersion()) {
<div class="current-subscription">
  <h2>{{ "manage-subscription.current-subscription" | translate }}</h2>
  <span
    class="sub"
    [class.basic]="featureStore.isBasic()"
    [class.startup]="featureStore.isStartup()"
    [class.pro]="featureStore.isPro()"
  >
    @if (featureStore.isBasic()) { BASIC } @else if (featureStore.isStartup()) {
    STARTUP } @else if (featureStore.isPro()) { PRO }
  </span>

  @if (store.loading()) {
  <app-loading-spinner [minimal]="true" />
  }
  @else if (store.subscriptionCanceled()) {
    <span>{{ "manage-subscription.canceled" | translate }}
      {{ store.periodEnd() | date : "dd.MM.yyyy HH:mm" }}
    </span>
  }
  @else {
    @if (store.canChangeSubscription()) {
      <a class="btn-secondary" [routerLink]="'tweak'">
        {{ "manage-subscription.tweak" | translate }}
      </a>
    }
    <button class="btn-danger" (click)="toggleCancelDialog()">
      {{ "manage-subscription.cancel" | translate }}
    </button>
    @if (!store.canChangeSubscription()) {
      <div class="flex-row-center-center gap-10">
        <fa-icon [icon]="faAnglesDown"></fa-icon>
        <span>{{ "manage-subscription.downgrade-scheduled" | translate }}</span>
      </div>
    }
  }
</div>
}
@if (currentUserStore.isTestVersion()) {
<h2>{{ "manage-subscription.test-version" | translate }}</h2>
<p>{{ "manage-subscription.test-version-text" | translate }}</p>
}
@else {

}
@if (!store.canChangeSubscription() && store.downgradeInformation() && !store.subscriptionCanceled()) {
  <app-downgrade-information
    [downgradeInformation]="store.downgradeInformation()"
  />
}

<app-dialog
  [dialogTitle]="'manage-subscription.cancel-confirm-title' | translate"
  [showDialog]="store.showCancelDialog()"
>
  <p>{{ "manage-subscription.cancel-confirm" | translate }}</p>
  <div class="btn-group">
    <button class="btn-secondary" (click)="cancelSubscription()">
      {{ "manage-subscription.cancel-confirm-button" | translate }}
    </button>
    <button class="btn-light" (click)="toggleCancelDialog()">
      {{ "generic.cancel" | translate }}
    </button>
  </div>
</app-dialog>
