import { Component, inject, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterStore } from '../register.store';
import { SkuModel } from '../sku.model';
import { SkuItemComponent } from './sku-item/sku-item.component';
import { skus } from './sku-list';

@Component({
    selector: 'app-sku-selection',
    imports: [TranslateModule, SkuItemComponent, FontAwesomeModule],
    templateUrl: './sku-selection.component.html',
    styleUrl: './sku-selection.component.scss'
})
export class SkuSelectionComponent implements OnInit {
  registerStore = inject(RegisterStore);

  faInfoCircle = faInfoCircle;
  skuModels: SkuModel[] = [];



  ngOnInit() {
    this.skuModels = skus;
    if (this.registerStore.selectedSku() === undefined) {
      this.registerStore.selectSku(skus[1]);
    }
  }

  selectTestVersion() {
    this.registerStore.setTestVersionSelected();
    this.registerStore.proceedToCustomerStep();
  }
}
