import { Component, inject } from '@angular/core';
import { LanguageSwitchStore } from '../language-switch/language-switch.store';
import { LogoComponent } from "../logo/logo.component";

@Component({
    selector: 'app-privacy',
    imports: [LogoComponent],
    templateUrl: './privacy.component.html',
    styleUrl: './privacy.component.scss'
})
export class PrivacyComponent {
  languageStore = inject(LanguageSwitchStore);
}

