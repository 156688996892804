import { DatePipe } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faForward } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureStore } from '../../shared/features/feature.store';
import { DowngradeInformation } from './downgrade-information.model';

@Component({
    selector: 'app-downgrade-information',
    imports: [TranslateModule, FontAwesomeModule, DatePipe],
    templateUrl: './downgrade-information.component.html',
    styleUrl: './downgrade-information.component.scss'
})
export class DowngradeInformationComponent {
  downgradeInformation = input<DowngradeInformation>();

  faForward = faForward;

  featureStore = inject(FeatureStore);
}
