@if (hasError && !minimized) {
<div class="error-container">
  <div class="error-container__errors">
    @for (error of errors; track $index) {
    <div class="error-container__errors__single">
      <fa-icon size="xl" class="error-icon" [icon]="error.icon"></fa-icon>
      <span class="error-text">{{
        error.error.errorMessageKey! | translate
      }}</span>
    </div>
    }
  </div>

  <div class="error-container__button">
    <button class="btn-dark" (click)="toggleMinimized()">
      -
    </button>
    <button class="btn-danger" [title]="'errors.dismiss-error' | translate" (click)="dismiss()">X</button>
  </div>
</div>
} @if (hasError && minimized) {
<div class="error-container error-container--dismissed">
  @for (error of errors; track $index) {
  <fa-icon
    size="xl"
    class="error-icon"
    [icon]="error.icon"
    (click)="toggleMinimized()"
  ></fa-icon>
  }
</div>
}
