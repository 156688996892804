import packageJson from '../../package.json';

export const environment = {
  //...
  // msal config details
  msalConfig: {
    clientId: 'a014fa4e-f94a-42fa-ade3-4faba00624b9',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://dev.flowstruct.de/home',
    postLogoutRedirectUri: 'https://dev.flowstruct.de/home',
    registerRedirect: 'https://dev.flowstruct.de/register',
  },
  apiConfig: {
    url: 'https://dev-flowstructapi-app.azurewebsites.net/api',
    scopes: ['api://6fb30f3d-6923-4b65-867b-2b49fb25188a/.default'],
  },
  tracking: {
    siteId: 2,
    enabled: true
  },
  appVersion: packageJson.version
};
