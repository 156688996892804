import { Component, input, output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DialogComponent } from "../../../shared/dialog/dialog.component";
import { FormField } from '../../../shared/form/form-field';
import { FormComponent } from '../../../shared/form/form.component';

@Component({
    selector: 'app-add-lane',
    imports: [TranslateModule, FormComponent, DialogComponent],
    templateUrl: './add-lane.component.html',
    styleUrl: './add-lane.component.scss'
})
export class AddLaneComponent {
  showDialog = input<boolean>(false);
  added = output<string>();
  canceled = output<void>();

  fields: FormField[] = [
    {
      fieldType: 'text',
      name: 'name',
      labelKey: 'diagrams.kanban.add-lane-dialog.name',
      required: true
    }
  ];

  onSave($event: FormGroup) {
    this.added.emit($event.value.name);
  }

  onCancel() {
    this.canceled.emit();
  }
}
