import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
    selector: 'app-confirmation-dialog',
    imports: [DialogComponent, TranslateModule],
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {
  titleKey = input<string>('');
  messageKey = input<string>('');
  confirmKey = input<string>('');
  cancelKey = input<string>('generic.cancel');
  show = input<boolean>(false);

  confirm = output<void>();
  cancel = output<void>();

  onConfirm(): void {
    this.confirm.emit();
  }

  onCancel(): void {
    this.cancel.emit();
  }
}
