import { CurrencyPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { SubscriptionRepository } from '../../renew-subscription/subscription.repository';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { SkuItemComponent } from '../../unauthenticated/register-wizard/sku-selection/sku-item/sku-item.component';
import { skus } from '../../unauthenticated/register-wizard/sku-selection/sku-list';
import { SkuModel } from '../../unauthenticated/register-wizard/sku.model';
import { ManageSubscriptionRepository } from '../manage-subscription.repository';
import { TweakSubscriptionStore } from './tweak-subscription.store';

@Component({
    selector: 'app-tweak-subscription',
    imports: [
        TranslateModule,
        SkuItemComponent,
        ReactiveFormsModule,
        LoadingSpinnerComponent,
        CurrencyPipe,
        DialogComponent,
    ],
    templateUrl: './tweak-subscription.component.html',
    styleUrl: './tweak-subscription.component.scss'
})
export class TweakSubscriptionComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  numberInput$ = new Subject<number>();

  skuModels = signal<SkuModel[]>([]);
  showConfirmDialog = signal<boolean>(false);

  router = inject(Router);
  store = inject(TweakSubscriptionStore);
  subscriptionRepository = inject(SubscriptionRepository);
  manageSubscriptionRepository = inject(ManageSubscriptionRepository);

  ngOnInit(): void {
    this.skuModels.set(skus.slice());
    this.loadCustomer();

    this.numberInput$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe({
        next: (number) => {
          this.getPreviewOnChange();
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCustomer() {
    this.store.setLoading();

    this.subscriptionRepository
      .getCurrentCustomer()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customer) => {
          this.store.setCurrentAmountOfUsers(customer.givenUserCount);
          this.store.setCustomerId(customer.id);
          this.store.selectSku(
            this.skuModels().find((skuModel) => skuModel.sku === customer.sku)!
          );
          this.store.stopLoading();
        },
        error: (error) => {
          this.store.stopLoading();
        },
      });
  }

  selectSku(sku: SkuModel) {
    if (
      this.store.selectedAmountOfUsers() &&
      this.store.selectedAmountOfUsers()! > sku.maxAmountOfUsers
    ) {
      this.store.selectAmountOfUsers(sku.maxAmountOfUsers);
    }
    this.store.selectSku(sku);

    this.getPreviewOnChange();
  }

  onUserAmountChanged($event: any) {
    let number = Number((<HTMLInputElement>$event.target).value);

    const greaterThanMax = number > this.store.selectedSku()!.maxAmountOfUsers;
    if (greaterThanMax) {
      number = this.store.selectedSku()!.maxAmountOfUsers;
    }

    this.store.selectAmountOfUsers(number);
    this.numberInput$.next(number);
  }

  getPreviewOnChange() {
    this.store.setLoading();
    this.subscriptionRepository
      .getPreview(
        this.store.selectedSku()!.sku,
        this.store.customerId()!,
        this.store.selectedAmountOfUsers()!
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (preview) => {
          this.store.setPreview(preview);
          this.store.stopLoading();
        },
        error: (error) => {
          this.store.stopLoading();
        },
      });
  }

  openConfirmChangesDialog() {
    this.showConfirmDialog.set(true);
  }

  closeConfirmChangesDialog() {
    this.showConfirmDialog.set(false);
  }

  confirm() {
    this.store.setLoading();
    this.manageSubscriptionRepository
      .upgradeOrDowngradeSubscription({
        amountOfUsers: this.store.selectedAmountOfUsers()!,
        customerId: this.store.customerId()!,
        prorationDate: this.store.preview()!.prorationDate,
        sku: this.store.selectedSku()!.sku,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.store.stopLoading();
          this.closeConfirmChangesDialog();
          this.router.navigate(['/user/manage-subscription']);

        },
        error: (error) => {
          this.store.stopLoading();
          this.closeConfirmChangesDialog();
        },
      });
  }

  cancel() {
    this.closeConfirmChangesDialog();
  }
}
