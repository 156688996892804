import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { CookieConsentStore } from './cookie-consent.store';

@Component({
  selector: 'app-cookie-consent',
  imports: [TranslateModule, RouterModule],
  templateUrl: './cookie-consent.component.html',
  styleUrl: './cookie-consent.component.scss'
})
export class CookieConsentComponent {
  tracker = inject(MatomoTracker);
  store = inject(CookieConsentStore);

  enableTracking(): void {
    this.tracker.rememberConsentGiven();
    this.store.rememberConsentGiven();
  }

  disableTracking(): void {
    this.tracker.forgetConsentGiven();
    this.store.forgetConsentGiven();
  }
}
