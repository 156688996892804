import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { FormField } from '../../../shared/form/form-field';
import { FormComponent } from "../../../shared/form/form.component";
import { Priority } from '../priority.enum';
import { NewPriorityIssue } from './new-priority-issue';

@Component({
    selector: 'app-add-priority-issue',
    imports: [DialogComponent, TranslateModule, FormComponent],
    templateUrl: './add-priority-issue.component.html',
    styleUrl: './add-priority-issue.component.scss'
})
export class AddPriorityIssueComponent {
  @Input({required: true}) showDialog!: boolean;
  @Input({required: true}) forPriority!: Priority;
  @Output() addedPriority: EventEmitter<NewPriorityIssue> = new EventEmitter<NewPriorityIssue>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  fields: FormField[] = [
    {
      name: 'title',
      fieldType: 'text',
      required: true,
      validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100)],
      labelKey: 'diagrams.priority.addIssue.form.title'
    },
    {
      name: 'description',
      fieldType: 'textarea',
      required: false,
      validators: [],
      labelKey: 'diagrams.priority.addIssue.form.description'
    }
  ];

  addIssue(form: FormGroup): void {
    const newIssue: NewPriorityIssue = {
      title: form.get('title')!.value!,
      description: form.get('description')?.value,
      priority: this.forPriority,
    };
    this.addedPriority.emit(newIssue);
  }

  cancel(): void {
    this.canceled.emit();
  }
}
