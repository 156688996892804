import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-data-group',
    imports: [TranslateModule],
    templateUrl: './data-group.component.html',
    styleUrl: './data-group.component.scss'
})
export class DataGroupComponent {
  @Input({ required: true }) labelKey!: string;
  @Input() minimal: boolean = false;
}
