import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStore } from '../../auth/current-user/current-user.store';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { FormField, SelectOption } from '../../shared/form/form-field';
import { FormComponent } from '../../shared/form/form.component';
import { WorkgroupRepository } from '../workgroup.repository';

@Component({
    selector: 'app-add-workgroup',
    imports: [DialogComponent, TranslateModule, FormComponent],
    templateUrl: './add-workgroup.component.html',
    styleUrl: './add-workgroup.component.scss'
})
export class AddWorkgroupComponent implements OnInit, OnDestroy {
  currentUserStore = inject(CurrentUserStore);

  destroy$ = new Subject<void>();
  @Input() showDialog = false;

  @Output() workgroupAdded = new EventEmitter<void>();
  @Output() showDialogChange = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();

  fields: FormField[] = [];

  private userSelectOptions: SelectOption[] | any[] = [];

  constructor(private readonly workgroupRepository: WorkgroupRepository) {}

  ngOnInit(): void {
    this.workgroupRepository.getCustomerUsers()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (users) => {
        this.userSelectOptions = users.map((user) => ({
          key: user.username,
          value: user.id,
          name: user.username,
          locked: this.currentUserStore.id() === user.id,
        }));
        this.intializeFields();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  intializeFields(): void {
    this.fields = [
      {
        name: 'name',
        labelKey: 'workgroups.addDialog.name',
        fieldType: 'text',
        required: true,
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      },
      {
        name: 'userIds',
        labelKey: 'workgroups.addDialog.addUsers',
        fieldType: 'multi-select',
        required: false,
        defaultSelectedMultiSelectItems: this.userSelectOptions.filter((user) => user.locked).slice(),
        displayProp: 'name',
        selectOptions: this.userSelectOptions.filter((user) => !user.locked).slice(),
      }
    ];
  }

  addWorkgroup(form: FormGroup): void {
    this.loading.emit(true);
    this.workgroupRepository
      .addWorkgroup(form.get('name')!.value, form.get('userIds')!.value.map((user: SelectOption) => user.value))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.workgroupAdded.emit();
          this.loading.emit(false);
        },
        error: () => {
          this.loading.emit(false);
        },
      });
  }

  cancel(): void {
    this.showDialog = false;
    this.showDialogChange.emit(false);
  }
}
