import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpService } from '../shared/http.service';
import { CreateDiagramModel } from './models/create-diagram.model';
import { DiagramModel } from './models/diagram.model';
import { UpdateDiagramModel } from './models/update-diagram.model';

const apiConfig = environment.apiConfig;

@Injectable({
  providedIn: 'root'
})
export class DiagramRepository {

  constructor(private readonly httpService: HttpService) {}

  get(id: string): Observable<DiagramModel> {
    return this.httpService.get<DiagramModel>(apiConfig.url + '/diagram/' + id);
  }

  getForProject(projectId: string): Observable<DiagramModel[]> {
    return this.httpService.get<DiagramModel[]>(apiConfig.url + '/diagram/project/' + projectId);
  }

  create(param: CreateDiagramModel): Observable<DiagramModel> {
    return this.httpService.post<DiagramModel>(apiConfig.url + '/diagram/create', param);
  }

  update(param: UpdateDiagramModel): Observable<DiagramModel> {
    return this.httpService.put<DiagramModel>(apiConfig.url + `/diagram/update/${param.id}`, param);
  }

  getPrivate(): Observable<DiagramModel[]> {
    return this.httpService.get<DiagramModel[]>(apiConfig.url + '/diagram/private');
  }

  archive(id: string): Observable<void> {
    return this.httpService.get<void>(apiConfig.url + '/diagram/archive/' + id);
  }

  delete(id: string): Observable<void> {
    return this.httpService.delete<void>(apiConfig.url + '/diagram/delete/' + id);
  }
}
