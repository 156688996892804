import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSwitchStore } from './language-switch.store';

@Component({
    selector: 'app-language-switch',
    imports: [],
    templateUrl: './language-switch.component.html',
    styleUrl: './language-switch.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSwitchComponent implements OnInit {
  store = inject(LanguageSwitchStore);

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.store.setLanguage(this.store.currentLanguage() ?? this.translateService.currentLang);

    if (this.store.currentLanguage()) {
      this.translateService.use(this.store.currentLanguage()!);
    }
  }

  toggleDropdown(): void {
    this.store.toggleDropdown();
  }

  setLanguage(language: string): void {
    this.translateService.use(language);
    this.store.setLanguage(language);
    this.toggleDropdown();
  }
}
