import { Component, inject, input, OnInit, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerRepository } from '../../admin-customers/customer.repository';
import { DataTableColumn } from '../../shared/data-table/data-table-column';
import { DataTableComponent } from "../../shared/data-table/data-table.component";
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { UserModel } from '../../user/user.model';

@Component({
    selector: 'app-users',
    imports: [TranslateModule, DataTableComponent, LoadingSpinnerComponent],
    templateUrl: './users.component.html',
    styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit {

  customerService = inject(CustomerRepository);

  customerId = input<string>();

  loading = signal(false);
  users = signal<UserModel[]>([]);

  columns: DataTableColumn[] = [
    { prop: 'username', name: 'Username', translateKey: 'admin.customers.detail.users.table.username', type: 'text' },
    { prop: 'emailAddress', name: 'Email', translateKey: 'admin.customers.detail.users.table.email', type: 'text' },
    { prop: 'role', name: 'Role', translateKey: 'admin.customers.detail.users.table.role', type: 'text' },
    { prop: 'active', name: 'Active', translateKey: 'admin.customers.detail.users.table.active', type: 'boolean' },
    { prop: 'createdAt', name: 'Created At', translateKey: 'admin.customers.detail.users.table.createdAt', type: 'datetime' }
  ];

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers() {
    this.loading.set(true);
    this.customerService.getCustomerUsersById(this.customerId()!).subscribe({
      next: (users) => {
        this.users.set(users);
        this.loading.set(false);
      },
      error: () => {
        this.loading.set(false);
      },
    });
  }
}
