import { withDevtools, withStorageSync } from "@angular-architects/ngrx-toolkit";
import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";

export type CookieConsentState = {
  answered: boolean;
  consentGiven: boolean;
}

const initialState: CookieConsentState = {
  answered: false,
  consentGiven: false
}

export const CookieConsentStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ }) => ({})),
  withDevtools('CookieConsentStore'),
  withStorageSync('CookieConsentStore'),
  withMethods((store) => ({
    rememberConsentGiven: () => patchState(store, { answered: true, consentGiven: true }),
    forgetConsentGiven: () => patchState(store, { answered: true, consentGiven: false }),
    reset: () => patchState(store, { answered: false, consentGiven: false })
  })
));
