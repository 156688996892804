import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

export type DataPointType = 'text' | 'date' | 'datetime' | 'number' | 'currency' | 'percentage' | 'boolean';

@Component({
    selector: 'app-data-point',
    imports: [TranslateModule, CommonModule],
    templateUrl: './data-point.component.html',
    styleUrl: './data-point.component.scss'
})
export class DataPointComponent {
  @Input({ required: true }) labelKey!: string;
  @Input({ required: true }) value!: any;
  @Input({ required: true }) type!: DataPointType;
}
