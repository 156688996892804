<div class="version" [class.collapsed]="menuStore.collapsed()">
  @if (!menuStore.collapsed()) {
  <span
    >{{ version() }}
    (Amber)
  </span>
  }
  <fa-icon [icon]="faInfoCircle" size="lg" (click)="onShowChangelog()" />
</div>
<app-dialog
  [showDialog]="showChangeLog()"
  [dialogTitle]="('version.changelog.title' | translate) + ' ' + version() + ' (Amber)'"
>
  <p>🇩🇪 Initiale Version</p>
  <hr />
  <p>🇬🇧 Initial version</p>
  <button class="btn-secondary" (click)="onCloseChangeLog()">
    {{ "generic.close" | translate }}
  </button>
</app-dialog>
