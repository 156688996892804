import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitchComponent } from "../../shared/language-switch/language-switch.component";
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { CheckDataComponent } from './check-data/check-data.component';
import { DataInputComponent } from './data-input/data-input.component';
import { PaymentComponent } from './payment/payment.component';
import { ProgressDisplayComponent } from './progress-display/progress-display.component';
import { RegisterStore } from './register.store';
import { RegistrationDoneComponent } from './registration-done/registration-done.component';
import { SkuSelectionComponent } from './sku-selection/sku-selection.component';

@Component({
    selector: 'app-wizard-container',
    imports: [
        SkuSelectionComponent,
        DataInputComponent,
        PaymentComponent,
        ProgressDisplayComponent,
        RegistrationDoneComponent,
        CheckDataComponent,
        LanguageSwitchComponent,
        AuthenticateComponent,
        TranslateModule,
        RouterModule
    ],
    templateUrl: './wizard-container.component.html',
    styleUrl: './wizard-container.component.scss'
})
export class WizardContainerComponent {
  registerStore = inject(RegisterStore);
}
