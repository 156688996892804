<main>
  <div class="renew-subscription-container">
    <div class="renew-subscription gap-10">
      @if (renewSubscriptionStore.loading()) {
      <app-loading-spinner />
      } @else { @if (renewSubscriptionStore.paymentCompleted()) {
      <div class="flex-column-center-center gap-20 width-100">
        <app-logo></app-logo>
        <h1>{{ "renew-subscription.payment-completed-title" | translate }}</h1>
      </div>
      <p>{{ "renew-subscription.payment-completed-message" | translate }}</p>
      } @else { @if (currentUserStore.isTestVersion()) {
      <div class="flex-row-center-spacebetween width-100">
        <app-logo></app-logo>
        <h1>{{ "renew-subscription.test-version-title" | translate }}</h1>
      </div>
      <p>{{ "renew-subscription.test-version-hint" | translate }}</p>
      } @else {
      <div class="flex-row-center-spacebetween width-100">
        <app-logo></app-logo>
        <h1>{{ "renew-subscription.title" | translate }}</h1>
      </div>
      <p>{{ "renew-subscription.message" | translate }}</p>

      }
      <section class="flex-row-center-center gap-20 wrap">
        @for (sku of skuModels; track $index) {
        <app-sku-item
          [sku]="sku"
          (click)="selectSku(sku)"
          [class.selected]="
            renewSubscriptionStore.selectedSku()?.sku === sku.sku
          "
        />
        }
      </section>
      <p class="selected-sku">
        {{ "renew-subscription.selected-sku" | translate }}:
        {{ renewSubscriptionStore.selectedSku()?.name }}
      </p>
      <div class="flex-row-center-center gap-20">
        <p class="amount-of-users">
          {{ "renew-subscription.amount-of-users" | translate }}
        </p>
        @if (renewSubscriptionStore.selectedSku()?.sku === 1) {
        <app-number-slider
          [min]="1"
          [max]="renewSubscriptionStore.selectedSku()?.maxAmountOfUsers ?? 1"
          [value]="
            renewSubscriptionStore.amountOfUsers() ??
            renewSubscriptionStore.defaultAmountOfUsers()
          "
          (changed)="onUserAmountChanged($event)"
        ></app-number-slider>
        } @else if (renewSubscriptionStore.selectedSku()?.sku === 2) {
        <input
          type="number"
          min="0"
          max="1000"
          pattern="[0-9]{1,4}"
          required
          [value]="
            renewSubscriptionStore.amountOfUsers() ??
            renewSubscriptionStore.defaultAmountOfUsers()
          "
          (input)="onUserAmountChanged($event)"
        />
        } @else {
        <p class="amount-of-users">
          {{ "renew-subscription.single-license" | translate }}
        </p>
        }
      </div>
      <p class="expected-cost">
        {{ "renew-subscription.expected-cost" | translate }}:
        {{ renewSubscriptionStore.expectedCost() | currency : "EUR" }}
      </p>
      <div class="btn-group">
        @if (renewSubscriptionStore.loadingPaymentLink()) {
        <app-loading-spinner [minimal]="true" />
        } @else if (renewSubscriptionStore.paymentLinkLoaded()) {
        <a [href]="renewSubscriptionStore.paymentLink()" class="btn-secondary">
          {{ "renew-subscription.pay-now" | translate }}
        </a>
        } @else {
        <button class="btn-dark">
          {{ "renew-subscription.feedback-btn" | translate }}
        </button>
        <button class="btn-secondary" (click)="onPayment()">
          {{ "renew-subscription.to-payment-btn" | translate }}
        </button>
        }
      </div>
      } }
    </div>
  </div>
</main>
