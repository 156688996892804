import { CurrencyPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStore } from '../auth/current-user/current-user.store';
import { FeatureStore } from '../shared/features/feature.store';
import { LoadingSpinnerComponent } from '../shared/loading-spinner/loading-spinner.component';
import { LogoComponent } from '../shared/logo/logo.component';
import { NumberSliderComponent } from '../shared/number-slider/number-slider.component';
import { RegisterRepository } from '../unauthenticated/register-wizard/register.repository';
import { SkuItemComponent } from '../unauthenticated/register-wizard/sku-selection/sku-item/sku-item.component';
import { skus } from '../unauthenticated/register-wizard/sku-selection/sku-list';
import { SkuModel } from '../unauthenticated/register-wizard/sku.model';
import { RenewSubscriptionStore } from './renew-subscription.store';
import { SubscriptionRepository } from './subscription.repository';

@Component({
    selector: 'app-renew-subscription',
    imports: [
        TranslateModule,
        LogoComponent,
        SkuItemComponent,
        NumberSliderComponent,
        CurrencyPipe,
        LoadingSpinnerComponent,
    ],
    templateUrl: './renew-subscription.component.html',
    styleUrl: './renew-subscription.component.scss'
})
export class RenewSubscriptionComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  currentUserStore = inject(CurrentUserStore);
  featureStore = inject(FeatureStore);
  renewSubscriptionStore = inject(RenewSubscriptionStore);
  registerRepository = inject(RegisterRepository);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);

  skuModels: SkuModel[] = [];

  subscriptionRepository = inject(SubscriptionRepository);

  ngOnInit() {
    this.skuModels = skus.slice();
    if (!this.renewSubscriptionStore.selectedSku()) {
      this.loadCustomer();
    }

    this.useSessionIdConfirmPaymentAndRedirect();
  }

  useSessionIdConfirmPaymentAndRedirect() {
    const sessionId = this.activatedRoute.snapshot.queryParams['sessionId'];
    if (sessionId) {
      this.renewSubscriptionStore.setSessionId(sessionId);
      this.registerRepository
        .confirmPayment(
          sessionId,
          this.renewSubscriptionStore.currentCustomerId()!
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.renewSubscriptionStore.setPaymentCompleted(true);
            this.currentUserStore.loginAndSetUserInfo();
            this.featureStore
              .getFeatures()
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: () => {
                  setTimeout(() => {
                    this.renewSubscriptionStore.reset();
                    this.router.navigate(['/user/dashboard']);
                  }, 4000);
                },
              });
          },
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCustomer() {
    this.renewSubscriptionStore.setLoading(true);
    this.subscriptionRepository
      .getCurrentCustomer()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customer) => {
          this.renewSubscriptionStore.setCurrentCustomerId(customer.id);
          this.renewSubscriptionStore.selectSku(
            skus.find((s) => s.sku === customer.sku) ?? skus[1]
          );
          this.renewSubscriptionStore.setAmountOfUsers(customer.givenUserCount);
          this.renewSubscriptionStore.setLoading(false);
        },
      });
  }

  selectSku(sku: SkuModel) {
    this.renewSubscriptionStore.selectSku(sku);
    if (sku.sku === 0) {
      this.renewSubscriptionStore.setAmountOfUsers(1);
    }
  }

  onUserAmountChanged($event: any) {
    let number;
    if ($event instanceof Event) {
      number = parseInt((<HTMLInputElement>$event.target).value);
    } else {
      number = $event;
    }
    this.renewSubscriptionStore.setAmountOfUsers(number);
  }

  onPayment() {
    this.renewSubscriptionStore.setLoadingPaymentLink(true);
    const params = {
      sku: this.renewSubscriptionStore.selectedSku()!.sku,
      amountOfUsers: this.renewSubscriptionStore.amountOfUsers(),
      customerId: this.renewSubscriptionStore.currentCustomerId(),
    };

    this.subscriptionRepository
      .getCheckoutSession(params.sku, params.customerId!, params.amountOfUsers!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (url) => {
          this.renewSubscriptionStore.setPaymentLink(url.sessionUrl);
          this.renewSubscriptionStore.setLoadingPaymentLink(false);
          this.renewSubscriptionStore.setPaymentLinkLoaded(true);
        },
        error: (error) => {
          this.renewSubscriptionStore.setLoadingPaymentLink(false);
        },
      });
  }
}
