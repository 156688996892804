import { Component, input } from '@angular/core';

@Component({
    selector: 'app-logo',
    imports: [],
    templateUrl: './logo.component.html',
    styleUrl: './logo.component.scss'
})
export class LogoComponent {
  width = input('400px');
  inverted = input(false);
}
