import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { Subject, takeUntil } from 'rxjs';
import { DataGroupComponent } from "../../../shared/data-group/data-group.component";
import { DataPointComponent } from "../../../shared/data-point/data-point.component";
import { DialogComponent } from "../../../shared/dialog/dialog.component";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { TermsOfServiceComponent } from "../../../shared/terms-of-service/terms-of-service.component";
import { RegisterRepository } from '../register.repository';
import { RegisterStore } from '../register.store';
import { SkuItemComponent } from "../sku-selection/sku-item/sku-item.component";

@Component({
    selector: 'app-check-data',
    imports: [TranslateModule, SkuItemComponent, DataGroupComponent, DataPointComponent, CommonModule, LoadingSpinnerComponent, DialogComponent, TermsOfServiceComponent],
    templateUrl: './check-data.component.html',
    styleUrl: './check-data.component.scss'
})
export class CheckDataComponent implements OnDestroy {
  destroy$ = new Subject<void>();
  loading = signal<boolean>(false);

  registerStore = inject(RegisterStore);
  registerRepository = inject(RegisterRepository);
  tracker = inject(MatomoTracker);

  showTermsOfService = signal<boolean>(false);

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  proceed() {
    this.loading.set(true);
    if (this.registerStore.createdAccount()) {
      if (this.registerStore.testVersionSelected()) {
        this.registerStore.proceedToDoneStep();
        return;
      } else {
        this.registerStore.proceedToPaymentStep();
        return;
      }
    }

    if (this.registerStore.testVersionSelected()) {
      this.registerRepository.registerTestVersionForCustomer(this.registerStore.addCustomerParam()!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.registerStore.setCreatedAccount();
          this.loading.set(false);
          this.tracker.trackGoal(1);
          this.registerStore.setDone();
          this.registerStore.proceedToDoneStep();
        }
      });
    } else {
      this.registerRepository.registerCustomer(this.registerStore.addCustomerParam()!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customerId: string) => {
          this.registerStore.setCreatedAccount();
          this.registerStore.setCustomerId(customerId);
          this.loading.set(false);
          this.registerStore.proceedToPaymentStep();
        }
      });
    }
  }

  showTerms() {
    this.showTermsOfService.set(true);
  }

  hideTerms() {
    this.showTermsOfService.set(false);
  }
}
