import { Component, input, OnDestroy, OnInit, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { DiagramRepository } from '../diagram.repository';
import { DiagramModel } from '../models/diagram.model';
import { UpdateDiagramModel } from '../models/update-diagram.model';
import { LeanCanvasModel } from './lean-canvas.model';

@Component({
    selector: 'app-lean-canvas',
    imports: [
        TranslateModule,
        ReactiveFormsModule,
        FontAwesomeModule
    ],
    templateUrl: './lean-canvas.component.html',
    styleUrl: './lean-canvas.component.scss'
})
export class LeanCanvasComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  formGroup: FormGroup | undefined;
  diagramJson$: Subject<string | undefined> = new Subject<string | undefined>();
  faArrowLeft = faArrowLeft;

  diagram = input<DiagramModel | undefined>(undefined);
  changed = output<UpdateDiagramModel>();

  constructor(
    private readonly diagramRepository: DiagramRepository,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.initalizeForm(this.diagram()!);
    this.diagramJson$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe({
        next: (diagramJson) => {
          if (diagramJson) {
            const updateDiagram: UpdateDiagramModel = {
              id: this.diagram()!.id!,
              diagramJson: diagramJson,
              name: this.diagram()!.name,
            };
            this.changed.emit(updateDiagram);
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initalizeForm(diagram: DiagramModel): void {
    const data: LeanCanvasModel = JSON.parse(diagram.diagramJson);

    this.formGroup = new FormGroup({
      problem: new FormControl(data.problem),
      alternatives: new FormControl(data.alternatives),
      solution: new FormControl(data.solution),
      metrics: new FormControl(data.metrics),
      uniqueValueProposition: new FormControl(data.uniqueValueProposition),
      unfairAdvantage: new FormControl(data.unfairAdvantage),
      customerSegments: new FormControl(data.customerSegments),
      channels: new FormControl(data.channels),
      costStructure: new FormControl(data.costStructure),
      revenueStreams: new FormControl(data.revenueStreams),
      highLevelConcept: new FormControl(data.highLevelConcept),
      earlyAdopters: new FormControl(data.earlyAdopters),
    });
  }

  onInputChanged(): void {
    this.diagramJson$.next(
      JSON.stringify({ ...this.formGroup?.value, type: 'lean-canvas' })
    );
  }
}
