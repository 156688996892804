import { Component, inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCheckCircle,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { RegisterRepository } from '../register.repository';
import { RegisterStore } from '../register.store';

@Component({
    selector: 'app-registration-done',
    imports: [TranslateModule, FontAwesomeModule],
    templateUrl: './registration-done.component.html',
    styleUrl: './registration-done.component.scss'
})
export class RegistrationDoneComponent implements OnDestroy {
  destroy$ = new Subject<void>();
  registerStore = inject(RegisterStore);
  router = inject(Router);
  registerRepository = inject(RegisterRepository);

  faCheckCircle = faCheckCircle;
  faXmark = faXmarkCircle;

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClick() {
    this.router.navigate(['/home']);
    setTimeout(() => {
      this.registerStore.reset();
    }, 500);

  }

  backToDataInput() {
    this.registerStore.resetRegistrationProcessState();
    this.registerStore.proceedToCustomerStep();
  }
}
