import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DataTableColumn } from './data-table-column';

@Component({
    selector: 'app-data-table',
    imports: [TranslateModule, CommonModule, FontAwesomeModule],
    templateUrl: './data-table.component.html',
    styleUrl: './data-table.component.scss'
})
export class DataTableComponent implements OnInit {
  @Input({ required: true }) columns!: DataTableColumn[];
  @Input({ required: true }) data!: any[];
  @Input() onSelectRow: (row: typeof this.type) => void = () => {};

  constructor(private readonly translateService: TranslateService) {}

  type: any = undefined;

  rows: any[] = [];
  locale!: string;

  ngOnInit(): void {
    this.locale = this.translateService.currentLang;
    this.rows = this.buildTableData();
  }

  buildTableData(): any[] {
    return this.data.map((row: any) => {
      this.type = typeof row;
      return {
        id: row.id,
        cells: this.columns.map((column: DataTableColumn) => {
          return {
            value: column.prop ? row[column.prop] : undefined,
            column: column.prop,
            type: column.type,
            actions: column.actions,
            enumStates: column.enumStates,
          };
        }),
        onSelect: this.onSelectRow,
      };
    });
  }

  executeAction(
    $event: MouseEvent,
    row: typeof this.type,
    action: (arg: typeof this.type) => void
  ): void {
    $event.stopPropagation();
    action(row);
  }
}
