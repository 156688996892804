import { Component, inject, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { ErrorService } from '../../../shared/error/error.service';
import { RegisterStore } from '../register.store';

const msalConfig = environment.msalConfig;
const scopes = environment.apiConfig.scopes;

@Component({
    selector: 'app-authenticate',
    imports: [TranslateModule],
    templateUrl: './authenticate.component.html',
    styleUrl: './authenticate.component.scss'
})
export class AuthenticateComponent implements OnInit {
  destroy$ = new Subject<void>();

  registerStore = inject(RegisterStore);
  msalService = inject(MsalService);
  errorService = inject(ErrorService);

  interval!: NodeJS.Timeout;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.interval = setInterval(() => {
      if (this.authService.tenantId) {
        setTimeout(() => {
          this.errorService.clearError();
          clearInterval(this.interval);
          this.registerStore.setTenantId(this.authService.tenantId);
          this.registerStore.proceedToConfirmationStep();
        }, 500);
      }
    });
  }

  async onLogin() {
    this.msalService
      .initialize()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.msalService.loginRedirect({
            scopes,
            redirectUri: msalConfig.registerRedirect,
          });
        },
      });
  }
}
