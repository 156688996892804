import { withDevtools, withStorageSync } from "@angular-architects/ngrx-toolkit";
import { computed } from "@angular/core";
import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { AddCustomerModel } from "../../admin-customers/add-customer/add-customer.model";
import { SkuModel } from "./sku.model";

export type RegisterStep = 'sku' | 'customer' | 'authenticate' | 'payment' | 'confirmation' | 'done';
export type RegistrationProcessState = 'creating' | 'data-validation-failed' | 'checkingPayment' | 'done' | 'paymentFailed';

export type RegisterState = {
  skuModel: SkuModel | undefined;
  step: RegisterStep;
  addCustomerParam: AddCustomerModel | undefined;
  registrationProcessState: RegistrationProcessState | undefined;
  paymentCompleted: boolean;
  testVersionSelected: boolean;
  createdAccount: boolean;
  customerId: string | undefined;
  hasAgreedToTerms: boolean;
}

const initialState: RegisterState = {
  skuModel: undefined,
  step: 'sku',
  addCustomerParam: undefined,
  registrationProcessState: undefined,
  paymentCompleted: false,
  testVersionSelected: false,
  createdAccount: false,
  customerId: undefined,
  hasAgreedToTerms: false,
};


export const RegisterStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ step, skuModel, addCustomerParam, registrationProcessState }) => ({
    stepIndex: computed(() => ['sku', 'customer', 'authenticate', 'confirmation', 'payment', 'done'].indexOf(step())),
    selectedSku: computed(() => skuModel()?.sku),
    previousIndex: computed(() => Math.max(0, ['sku', 'customer', 'authenticate', 'confirmation', 'payment', 'done'].indexOf(step()) - 1)),
    nextIndex: computed(() => Math.min(5, ['sku', 'customer', 'authenticate', 'confirmation', 'payment', 'done'].indexOf(step()) + 1)),
    adminEmailOrDefault: computed(() => addCustomerParam()?.adminEmail),
    maxAmountOfUsersForSku: computed(() => skuModel()?.maxAmountOfUsers),
    totalCost: computed(() => skuModel()!.price * addCustomerParam()!.givenUserCount * 1.19),
    totalBeforeTax: computed(() => skuModel()!.price * addCustomerParam()!.givenUserCount),
    canFinishRegistration: computed(() => registrationProcessState() === 'done'),
  })),
  withDevtools('RegisterStore'),
  withStorageSync('RegisterStore'),
  withMethods((store) => ({
    selectSku: (sku: SkuModel) => (patchState(store, { skuModel: sku, testVersionSelected: false })),
    proceedToCustomerStep: () => (patchState(store, { step: 'customer' })),
    proceedToAuthenticateStep: () => (patchState(store, { step: 'authenticate' })),
    proceedToPaymentStep: () => (patchState(store, { step: 'payment' })),
    proceedToConfirmationStep: () => (patchState(store, { step: 'confirmation' })),
    proceedToDoneStep: () => (patchState(store, { step: 'done' })),
    setCustomerParam: (param: AddCustomerModel) => (patchState(store, { addCustomerParam: param })),
    reset: () => (patchState(store, initialState)),
    setPaymentCompleted: () => (patchState(store, { paymentCompleted: true })),
    goBack: () => (patchState(store, { step: ['sku', 'customer', 'authenticate', 'confirmation', 'payment', 'done'][store.stepIndex() - 1] as RegisterStep })),
    setCreating: () => (patchState(store, { registrationProcessState: 'creating' })),
    setCheckingPayment: () => (patchState(store, { registrationProcessState: 'checkingPayment' })),
    setDone: () => (patchState(store, { registrationProcessState: 'done' })),
    setPaymentFailed: () => (patchState(store, { registrationProcessState: 'paymentFailed' })),
    setDataValidationFailed: () => (patchState(store, { registrationProcessState: 'data-validation-failed' })),
    resetRegistrationProcessState: () => (patchState(store, { registrationProcessState: undefined })),
    setTestVersionSelected: () => (patchState(store, { testVersionSelected: true })),
    setCreatedAccount: () => (patchState(store, { createdAccount: true })),
    setCustomerId: (customerId: string) => (patchState(store, { customerId: customerId })),
    setTenantId: (tenantId: string) => (patchState(store, { addCustomerParam: { ...store.addCustomerParam()!, tenantId: tenantId } })),
    toggleTermsAgreement: () => (patchState(store, { hasAgreedToTerms: !store.hasAgreedToTerms() })),
  }),
));
