import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { ProjectCardsComponent } from '../project-cards/project-cards.component';
import { ProjectSelectorComponent } from '../project-selector/project-selector.component';

@Component({
    selector: 'app-project-dashboard',
    imports: [ProjectSelectorComponent, ProjectCardsComponent],
    templateUrl: './project-dashboard.component.html',
    styleUrl: './project-dashboard.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDashboardComponent {
  projectId = signal<string>('private');

  onProjectChanged(projectId: string) {
    this.projectId.set(projectId);
  }
}
