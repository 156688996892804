import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { CustomerModel } from "../admin-customers/customer.model";
import { SubscriptionUpgradePreviewModel } from "../manage-subscription/tweak-subscription/subscription-upgrade-preview.model";
import { Sku } from "../models/enums/sku.enum";
import { HttpService } from "../shared/http.service";

const apiUrl = environment.apiConfig.url;

@Injectable({ providedIn: "root" })
export class SubscriptionRepository {
  constructor(private readonly httpService: HttpService) {}

  getCurrentCustomer(): Observable<CustomerModel> {
    return this.httpService.get(`${apiUrl}/subscription/current-customer`);
  }

  getCheckoutSession(sku: Sku, customerId: string, amountOfUsers: number): Observable<{ sessionUrl: string }> {
    return this.httpService.get(`${apiUrl}/subscription/checkout-session/${customerId}/${sku}/${amountOfUsers}`);
  }

  getPreview(sku: Sku, customerId: string, amountOfUsers: number): Observable<SubscriptionUpgradePreviewModel> {
    return this.httpService.get(`${apiUrl}/subscription/preview/${customerId}/${sku}/${amountOfUsers}`);
  }
}
