@if (languageStore.currentLanguage() === 'de') {
<h1>Nutzungsbedingungen (Stand: 25.12.2024)</h1>
<h2>1. Vertragsparteien</h2>
<p>
  1.1 Die Vertragsparteien bestehen aus Marvin Binder Software & Consulting,
  Hohe Straße 1, 56477 Rennerod (nachfolgend Anbieter genannt) sowie dem
  nutzenden Unternehmen/Einzelunternehmen/Freiberufler der Software (nachfolgend
  Nutzer genannt).
</p>
<h2>2. Gegenstand des Vertrags</h2>
<p>
  2.1 Der Anbieter stellt die Software FlowStruct zur eigenen Nutzung
  entgeltlich oder unentgeltlich nach gewähltem Abonnement zur Verfügung, die
  der Nutzer im gewählten Umfang seines Nutzungsabonnements verwenden kann. Dies
  beschränkt sich zunächst auf die Anzahl der anfänglich gewählten Nutzer sowie
  des Paketumfangs, kann im weiteren Verlauf der Nutzung jedoch erweitert
  werden. Im Falle der Testversion ist die Nutzung auf 7 Tage beschränkt, kann
  jedoch mit 3 Nutzern in der höchsten Paketstufe (Pro) verwendet werden.
</p>
<p>
  2.2 Die verfügbaren Funktionen sind in folgende Abonnementstufen unterteilt:
</p>
<ul>
  <li>
    Testversion (kostenlos, auf 7 Tage und 3 Nutzer nach Abschluss des Vertrags
    beschränkt)
  </li>
  <li>Basic (kostenpflichtig)</li>
  <li>Startup (kostenpflichtig)</li>
  <li>Pro (kostenpflichtig)</li>
</ul>
<p>
  2.3 Die enthaltenen Leistungen sind im Funktionskatalog bei anfänglicher
  Registrierung und Auswahl eines der o.g. Pakete ersichtlich.
</p>
<p>
  2.4 Der Anbieter behält sich das Recht vor, die Funktionen der einzelnen
  Abonnementstufen zu ändern, zu erweitern oder zu reduzieren. Der Nutzer wird
  über Änderungen der Funktionen informiert.
</p>
<p>
  2.5 Die Nutzungsdauer beschränkt sich auf die Dauer des Abrechnungszeitraums
  des gewählten Abonnements bis zur Kündigung. Die Kündigung ist jederzeit
  möglich und wird zum Ende des aktuellen Abrechnungszeitraums wirksam. Der
  Nutzer hat keinen Anspruch auf Rückerstattung bereits gezahlter Beträge.
</p>
<h2>3. Nutzungsvoraussetzungen</h2>
<p>
  3.1 Der Nutzer ist eine volljährige, voll geschäftsfähige und natürliche oder
  eine juristische Person und handelt in ihrem Auftrag. Der Nutzer verwendet die
  Software ferner ausschließlich für gewerbliche/unternehmerische und nicht für
  private Zwecke.
</p>
<p>
  3.2 Der Nutzer ist berechtigt, weitere Nutzer in die Software einzuladen und
  diesen Zugriff auf die Software zu gewähren. Der Nutzer ist für die Einhaltung
  der Nutzungsbedingungen durch die eingeladenen Nutzer verantwortlich. Der
  Nutzer ist ferner verpflichtet, die Zugangsdaten zu seinem Account geheim zu
  halten und nicht an Dritte weiterzugeben.
</p>
<p>
  3.3 Der Nutzer ist für alle Aktivitäten verantwortlich, die unter seinem
  Account stattfinden. Der Nutzer ist verpflichtet, den Anbieter unverzüglich zu
  informieren, falls er Kenntnis von einem Missbrauch seines Accounts erlangt.
</p>
<p>
  3.4 Der Nutzer ist dazu verpflichtet, die Software nicht zu manipulieren oder
  anderweitig in die Software einzugreifen. Der Nutzer ist ferner dazu
  verpflichtet, die Software nicht in einer Weise zu nutzen, die gegen geltendes
  Recht oder die guten Sitten verstößt.
</p>
<p>
  3.5 Der Nutzer ist dazu verpflichtet, die Software nicht in einer Weise zu
  nutzen, die die Rechte Dritter verletzt. Der Nutzer ist ferner dazu
  verpflichtet, den Anbieter von Ansprüchen Dritter freizustellen, die auf einer
  Verletzung der Rechte Dritter durch den Nutzer beruhen.
</p>
<p>
  3.6 Der Nutzer ist dazu verpflichtet, die Software nicht in einer Weise zu
  nutzen, die die Software oder die Systeme des Anbieters überlastet oder die
  Verfügbarkeit der Software beeinträchtigt.
</p>
<p>
  3.7 Der Nutzer erklärt sich damit einverstanden, dass der Anbieter auf die
  Leistungen Dritter oder Erfüllungsgehilfen zurückgreift, um die Software
  bereitzustellen oder deren Funktionalität zu gewährleisten.
</p>
<h2>4. Leistungsumfang</h2>
<p>
  4.1 Der Anbieter pflegt die Software in regelmäßigen Abständen und stellt so
  Funktionalität und Sicherheit der Software sicher.
</p>
<p>
  4.2 Der Anbieter stellt, sofern nicht durch höhere Gewalt verhindert, sicher,
  dass die Software zu 5 Tagen einer gewöhnlichen Arbeitswoche möglichst
  durchgängig verfügbar ist (Montag-Freitag). Dies bezieht sich auf die
  Erreichbarkeit der Software über das Internet. Wartungsarbeiten und Updates
  werden in der Regel in den Nachtstunden oder am Wochenende durchgeführt und
  der Nutzer wird im Vorfeld informiert, falls sich hieraus eine längere
  Nichtverfügbarkeit ergibt.
</p>
<p>
  4.3 Der Anbieter schuldet nicht die Herstellung und Aufrechterhaltung der
  Datenverbindung zwischen den Systemen des Nutzers und dem Anbietersystem.
</p>
<p>
  4.4 Der Nutzer räumt dem Anbieter das Recht ein, die durch den Kunden bei der
  Nutzung Software erstellten Daten im zur Erfüllung der Vertragspflichten
  erforderlichen Maße zu vervielfältigen. Gleiches gilt für vom Kunden in den
  Cloudspeicher geladene Dateien. Im Falle von Störungen ist der Anbieter
  berechtigt, notwendige Änderungen an Format oder Strukturierung der Daten
  vorzunehmen.
</p>
<h2>5. Zahlungsabwicklung</h2>
<p>
  5.1 Der Nutzer wird bei entgeltlicher Nutzung der Software monatlich im Voraus
  abgerechnet. Die Rechnung wird dem Nutzer per E-Mail zugesandt. Der Nutzer
  verpflichtet sich, die Rechnung innerhalb von 14 Tagen zu begleichen. Weitere
  Bedingungen sind den Nutzungsbedingungen des externen Zahlungsanbieters Stripe
  zu entnehmen, der für die gesamte Zahlungsabwicklung verwendet wird.
</p>
<p>
  5.2 Kündigt der Nutzer ein bestehendes Abonnement, so wird er im
  darauffolgenden Zeitraum nicht abgerechnet. Der Nutzer hat keinen Anspruch auf
  Rückerstattung bereits gezahlter Beträge.
</p>
<p>
  5.3 Führt der Nutzer ein Upgrade seines gewählten Abonnements durch, so wird
  ihm der Differenzbetrag nach Abschluss des Upgrades in Rechnung gestellt.
</p>
<p>
  5.4 Führt der Nutzer ein Downgrade seines gewählten Abonnements durch, so wird
  ihm der Differenzbetrag nach Abschluss des Downgrades nicht erstattet. Der
  Nutzer wird im nächsten Abrechnungszeitraum entsprechend des neuen Abonnements
  abgerechnet. Das bisherige Abonnement bleibt bis zum Ablauf des aktuellen
  Abrechnungszeitraumes bestehen.
</p>
<h2>6. Mitwirkungspflichten des Kunden</h2>
<p>
  6.1 Der Nutzer ist dazu verpflichtet, dem Anbieter alle für die Erfüllung des
  Vertrags notwendigen Informationen und Unterlagen zur Verfügung zu stellen.
  Der Nutzer ist ferner dazu verpflichtet, dem Anbieter bei der Erfüllung des
  Vertrags zu unterstützen, soweit dies zumutbar und notwendig ist.
</p>
<p>
  6.2 Der Nutzer ist dazu verpflichtet, dem Anbieter unverzüglich über
  Änderungen seiner Kontaktdaten zu informieren. Der Kunde ist ferner dazu
  verpflichtet, den Anbieter unverzüglich über Änderungen in seinem Unternehmen
  zu informieren, die die Erfüllung des Vertrags beeinflussen könnten.
</p>
<p>
  6.3 Im Falle einer Störung oder eines Fehlers in der Software ist der Nutzer
  dazu verpflichtet, den Anbieter unverzüglich und detailliert zu informieren,
  um die effiziente Behebung des Fehlers zu ermöglichen. Der Nutzer ist ferner
  dazu verpflichtet, den Anbieter bei der Behebung des Fehlers zu unterstützen,
  insofern möglich und zumutbar.
</p>
<h2>7. Haftung</h2>
<p>
  7.1 Der Anbieter haftet für Schäden, die durch Vorsatz oder grobe
  Fahrlässigkeit des Anbieters oder seiner Erfüllungsgehilfen entstehen. Der
  Anbieter haftet ferner für Schäden, die durch die Verletzung von
  Kardinalpflichten entstehen. Kardinalpflichten sind solche Pflichten, deren
  Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst
  ermöglichen und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf. Bei
  Verletzung einer Kardinalpflicht ist die Haftung – soweit der Schaden
  lediglich auf leichter Fahrlässigkeit beruht – beschränkt auf solche Schäden,
  mit deren Entstehung beim Einsatz der vertragsgegenständlichen Software
  typischerweise gerechnet werden muss.
</p>
<p>
  7.2 Der Anbieter haftet nicht für Schäden, die durch höhere Gewalt entstehen.
  Der Anbieter haftet ferner nicht für Schäden, die durch die Verletzung von
  Nebenpflichten entstehen. Nebenpflichten sind solche Pflichten, die nicht zu
  den Hauptpflichten des Vertrags gehören, aber dennoch für die ordnungsgemäße
  Durchführung des Vertrags notwendig sind.
</p>
<p>
  7.3 Im Übrigen ist die Haftung – gleich aus welchem Rechtsgrund –
  ausgeschlossen.
</p>
<p>
  7.4 Resultieren Schäden des Kunden aus dem Verlust von Daten, so haftet der
  Anbieter hierfür nicht, soweit die Schäden durch eine regelmäßige und
  vollständige Sicherung aller relevanten Daten durch den Nutzer vermieden
  worden wären. Der Nutzer wird eine regelmäßige und vollständige Datensicherung
  selbst oder durch einen Dritten durchführen bzw. durchführen lassen und ist
  hierfür allein verantwortlich.
</p>
<h2>8. Datensicherheit, Datenschutz, Datenspeicherung</h2>
<p>
  8.1 Der Anbieter speichert die Daten des Nutzers auf Servern in Deutschland.
  Der Anbieter trifft angemessene technische und organisatorische Maßnahmen, um
  die Sicherheit der Daten zu gewährleisten. Der Anbieter trifft ferner
  angemessene Maßnahmen, um die Daten vor Verlust, Zerstörung, unbefugtem
  Zugriff oder unbefugter Verarbeitung zu schützen.
</p>
<p>
  8.2 Nach Vertragsende stellt der Anbieter die vom Nutzer erstellten Daten nach
  Aufforderung binnen drei Wochen vollständig für den Nutzer zum Download zur
  Verfügung. Die Daten werden in einer CSV-Datei (oder einem anderen geeigneten
  Format) zur Verfügung gestellt.
</p>
<p>
  Binnen einer Woche, nachdem der Nutzer die Daten heruntergeladen hat,
  spätestens aber 30 Tage nach Vertragsende, löscht der Anbieter alle vom Nutzer
  auf seinen Servern abgelegten Daten vollständig.
</p>
<p>
  8.3 Nähere Bestimmungen sind der Datenschutzerklärung zu entnehmen:
  <a routerLink="/privacy" target="_blank">Datenschutzerklärung</a>
</p>
<h2>9. Übertragung der Rechte und Pflichten</h2>
<p>
  9.1 Die Abtretung der Rechte und Pflichten aus diesem Vertrag ist nur mit
  vorheriger schriftlicher Zustimmung des Anbieters zulässig. Der Anbieter ist
  berechtigt, Dritte mit der Erfüllung der Pflichten aus diesem Vertrag zu
  betrauen.
</p>
<h2>10. Sonstiges</h2>
<p>
  10.1 Diese Vereinbarung und ihre Änderungen sowie alle vertragsrelevanten
  Erklärungen, Mitteilungs- und Dokumentationspflichten bedürfen der
  Schriftform, soweit nicht eine andere Form vereinbart oder gesetzlich
  vorgeschrieben ist.
</p>
<p>
  10.2 Der Vertrag untersteht dem Recht der Bundesrepublik Deutschland unter
  Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den
  internationalen Warenkauf. Gerichtsstand ist der Sitz des Anbieters, soweit
  der Nutzer Kaufmann, juristische Person des öffentlichen Rechts oder
  öffentlich-rechtliches Sondervermögen ist.
</p>
<p>
  10.3 Sollten einzelne Bestimmung dieser Vereinbarung unwirksam sein, wird
  hierdurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. Die Parteien
  werden in diesem Fall zusammenwirken, um unwirksame Regelungen durch solche
  Regelungen zu ersetzen, die den unwirksamen Bestimmungen soweit wie möglich
  entsprechen.
</p>
} @else {
<h1>Terms of Service (as of 25.12.2024)</h1>
<h2>1. Contracting Parties</h2>
<p>
  1.1 The contracting parties consist of Marvin Binder Software & Consulting,
  Hohe Straße 1, 56477 Rennerod (hereinafter referred to as the provider) and
  the company/sole proprietorship/freelancer using the software (hereinafter
  referred to as the user).
</p>
<h2>2. Subject of the Contract</h2>
<p>
  2.1 The provider makes the FlowStruct software available for the user's own
  use, either for a fee or free of charge, depending on the chosen subscription,
  which the user can use to the extent of their chosen subscription. This is
  initially limited to the number of users and the scope of the package
  initially chosen, but can be expanded during the course of use. In the case of
  the trial version, use is limited to 7 days, but can be used with 3 users at
  the highest package level (Pro).
</p>
<p>
  2.2 The available functions are divided into the following subscription
  levels:
</p>
<ul>
  <li>
    Trial version (free, limited to 7 days and 3 users after the conclusion of
    the contract)
  </li>
  <li>Basic (paid)</li>
  <li>Startup (paid)</li>
  <li>Pro (paid)</li>
</ul>
<p>
  2.3 The included services are visible in the function catalog at the initial
  registration and selection of one of the above packages.
</p>
<p>
  2.4 The provider reserves the right to change, expand or reduce the functions
  of the individual subscription levels. The user will be informed about changes
  to the functions.
</p>
<p>
  2.5 The usage period is limited to the duration of the billing period of the
  chosen subscription until termination. Termination is possible at any time and
  becomes effective at the end of the current billing period. The user has no
  right to a refund of amounts already paid.
</p>
<h2>3. Usage Requirements</h2>
<p>
  3.1 The user is a legal adult, fully capable of conducting business, and a
  natural or legal person acting on their behalf. The user further uses the
  software exclusively for commercial/business purposes and not for private
  purposes.
</p>
<p>
  3.2 The user is entitled to invite other users to the software and grant them
  access to the software. The user is responsible for compliance with the terms
  of use by the invited users. The user is also obliged to keep the access data
  to their account secret and not to pass it on to third parties.
</p>
<p>
  3.3 The user is responsible for all activities that take place under their
  account. The user is obliged to inform the provider immediately if they become
  aware of any misuse of their account.
</p>
<p>
  3.4 The user is obliged not to manipulate the software or otherwise interfere
  with the software. The user is also obliged not to use the software in a way
  that violates applicable law or good morals.
</p>
<p>
  3.5 The user is obliged not to use the software in a way that infringes the
  rights of third parties. The user is also obliged to indemnify the provider
  against claims by third parties based on an infringement of third-party rights
  by the user.
</p>
<p>
  3.6 The user is obliged not to use the software in a way that overloads the
  software or the provider's systems or impairs the availability of the
  software.
</p>
<p>
  3.7 The user agrees that the provider may use the services of third parties or
  vicarious agents to provide the software or ensure its functionality.
</p>
<h2>4. Scope of Services</h2>
<p>
  4.1 The provider maintains the software at regular intervals to ensure the
  functionality and security of the software.
</p>
<p>
  4.2 The provider ensures, unless prevented by force majeure, that the software
  is available as continuously as possible for 5 days of a regular working week
  (Monday-Friday). This refers to the accessibility of the software via the
  internet. Maintenance work and updates are usually carried out at night or on
  weekends, and the user will be informed in advance if this results in a longer
  unavailability.
</p>
<p>
  4.3 The provider is not responsible for establishing and maintaining the data
  connection between the user's systems and the provider's system.
</p>
<p>
  4.4 The user grants the provider the right to reproduce the data created by
  the customer when using the software to the extent necessary to fulfill the
  contractual obligations. The same applies to files uploaded by the customer to
  the cloud storage. In the event of disruptions, the provider is entitled to
  make necessary changes to the format or structure of the data.
</p>
<h2>5. Payment Processing</h2>
<p>
  5.1 The user will be billed monthly in advance for the paid use of the
  software. The invoice will be sent to the user by email. The user undertakes
  to pay the invoice within 14 days. Further conditions can be found in the
  terms of use of the external payment provider Stripe, which is used for the
  entire payment processing.
</p>
<p>
  5.2 If the user terminates an existing subscription, they will not be billed
  in the following period. The user has no right to a refund of amounts already
  paid.
</p>
<p>
  5.3 If the user upgrades their chosen subscription, the difference will be
  billed after the upgrade is completed.
</p>
<p>
  5.4 If the user downgrades their chosen subscription, the difference will not
  be refunded after the downgrade is completed. The user will be billed
  according to the new subscription in the next billing period. The previous
  subscription remains in effect until the end of the current billing period.
</p>
<h2>6. Customer's Duties to Cooperate</h2>
<p>
  6.1 The user is obliged to provide the provider with all information and
  documents necessary for the fulfillment of the contract. The user is also
  obliged to support the provider in fulfilling the contract, as far as this is
  reasonable and necessary.
</p>
<p>
  6.2 The user is obliged to inform the provider immediately of any changes to
  their contact details. The customer is also obliged to inform the provider
  immediately of any changes in their company that could affect the fulfillment
  of the contract.
</p>
<p>
  6.3 In the event of a malfunction or error in the software, the user is
  obliged to inform the provider immediately and in detail to enable efficient
  error correction. The user is also obliged to support the provider in
  correcting the error, as far as possible and reasonable.
</p>
<h2>7. Liability</h2>
<p>
  7.1 The provider is liable for damages caused by intent or gross negligence on
  the part of the provider or its vicarious agents. The provider is also liable
  for damages caused by the breach of cardinal obligations. Cardinal obligations
  are those obligations whose fulfillment makes the proper execution of the
  contract possible in the first place and on whose compliance the user can
  regularly rely. In the event of a breach of a cardinal obligation, liability –
  insofar as the damage is based solely on slight negligence – is limited to
  such damages that are typically to be expected when using the contractual
  software.
</p>
<p>
  7.2 The provider is not liable for damages caused by force majeure. The
  provider is also not liable for damages caused by the breach of ancillary
  obligations. Ancillary obligations are those obligations that are not part of
  the main obligations of the contract but are nevertheless necessary for the
  proper execution of the contract.
</p>
<p>7.3 Otherwise, liability – regardless of the legal basis – is excluded.</p>
<p>
  7.4 If the customer's damages result from the loss of data, the provider is
  not liable for this, insofar as the damages could have been avoided by regular
  and complete backup of all relevant data by the user. The user will carry out
  regular and complete data backups themselves or have them carried out by a
  third party and is solely responsible for this.
</p>
<h2>8. Data Security, Data Protection, Data Storage</h2>
<p>
  8.1 The provider stores the user's data on servers in Germany. The provider
  takes appropriate technical and organizational measures to ensure the security
  of the data. The provider also takes appropriate measures to protect the data
  from loss, destruction, unauthorized access, or unauthorized processing.
</p>
<p>
  8.2 After the end of the contract, the provider will make the data created by
  the user available for download in full within three weeks upon request. The
  data will be provided in a CSV file (or another suitable format).
</p>
<p>
  Within one week after the user has downloaded the data, but no later than 30
  days after the end of the contract, the provider will delete all data stored
  by the user on its servers completely.
</p>
<p>
  8.3 Further provisions can be found in the privacy policy:
  <a routerLink="/privacy" target="_blank">Privacy Policy</a>
</p>
<h2>9. Transfer of Rights and Obligations</h2>
<p>
  9.1 The assignment of rights and obligations under this contract is only
  permitted with the prior written consent of the provider. The provider is
  entitled to entrust third parties with the fulfillment of the obligations
  under this contract.
</p>
<h2>10. Miscellaneous</h2>
<p>
  10.1 This agreement and its amendments, as well as all contract-relevant
  declarations, notification, and documentation obligations, must be in writing,
  unless another form is agreed upon or required by law.
</p>
<p>
  10.2 The contract is subject to the law of the Federal Republic of Germany,
  excluding the United Nations Convention on Contracts for the International
  Sale of Goods. The place of jurisdiction is the provider's registered office,
  provided the user is a merchant, a legal entity under public law, or a special
  fund under public law.
</p>
<p>
  10.3 Should individual provisions of this agreement be invalid, this shall not
  affect the validity of the remaining provisions. In this case, the parties
  will cooperate to replace invalid provisions with provisions that correspond
  as closely as possible to the invalid provisions.
</p>
}
