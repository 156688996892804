import { Component, inject, signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { UserMenuStore } from '../../user/user/user-menu.store';
import { DialogComponent } from "../dialog/dialog.component";

@Component({
  selector: 'app-version-display',
  imports: [FontAwesomeModule, TranslateModule, DialogComponent],
  templateUrl: './version-display.component.html',
  styleUrl: './version-display.component.scss'
})
export class VersionDisplayComponent {
  faInfoCircle = faInfoCircle;

  version = signal<string>(environment.appVersion);
  showChangeLog = signal<boolean>(false);

  menuStore = inject(UserMenuStore);

  onShowChangelog() {
    this.showChangeLog.set(true);
  }

  onCloseChangeLog() {
    this.showChangeLog.set(false);
  }
}
