import { withDevtools, withStorageSync } from "@angular-architects/ngrx-toolkit";
import { computed } from "@angular/core";
import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { SkuModel } from "../../unauthenticated/register-wizard/sku.model";
import { SubscriptionUpgradePreviewModel } from "./subscription-upgrade-preview.model";


export type TweakSubscriptionState = {
  selectedSku: SkuModel | undefined;
  currentAmountOfUsers: number;
  selectedAmountOfUsers: number | undefined;
  loading: boolean;
  customerId: string | undefined;
  preview: SubscriptionUpgradePreviewModel | undefined;
}

const initialState: TweakSubscriptionState = {
  selectedSku: undefined,
  currentAmountOfUsers: 0,
  selectedAmountOfUsers: undefined,
  loading: false,
  customerId: undefined,
  preview: undefined,
}

export const TweakSubscriptionStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ selectedSku, selectedAmountOfUsers }) => ({
    expectedCost: computed<number>(() => {
      if (selectedSku()) {
        return selectedSku()!.price * selectedAmountOfUsers()!;
      } else {
        return 0;
      }
    }),
  })),
  withDevtools('TweakSubscriptionStore'),
  withStorageSync('TweakSubscriptionStore'),
  withMethods((store) => ({
    setLoading: () => patchState(store, { loading: true }),
    stopLoading: () => patchState(store, { loading: false }),
    selectSku: (sku: SkuModel) => patchState(store, { selectedSku: sku }),
    selectAmountOfUsers: (amount: number) => patchState(store, { selectedAmountOfUsers: amount }),
    setCurrentAmountOfUsers: (amount: number) => patchState(store, { currentAmountOfUsers: amount }),
    setCustomerId: (customerId: string) => patchState(store, { customerId }),
    setPreview: (preview: SubscriptionUpgradePreviewModel) => patchState(store, { preview }),
    reset: () => patchState(store, initialState),
  })
));
