import { DatePipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStore } from '../auth/current-user/current-user.store';
import { SubscriptionRepository } from '../renew-subscription/subscription.repository';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { FeatureStore } from '../shared/features/feature.store';
import { LoadingSpinnerComponent } from '../shared/loading-spinner/loading-spinner.component';
import { DowngradeInformationComponent } from './downgrade-information/downgrade-information.component';
import { ManageSubscriptionRepository } from './manage-subscription.repository';
import { ManageSubscriptionStore } from './manage-subscription.store';

@Component({
  selector: 'app-manage-subscription',
  imports: [
    TranslateModule,
    LoadingSpinnerComponent,
    DatePipe,
    DialogComponent,
    RouterModule,
    FontAwesomeModule,
    DowngradeInformationComponent,
  ],
  templateUrl: './manage-subscription.component.html',
  styleUrl: './manage-subscription.component.scss',
})
export class ManageSubscriptionComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();

  featureStore = inject(FeatureStore);
  currentUserStore = inject(CurrentUserStore);
  store = inject(ManageSubscriptionStore);

  subscriptionRepository = inject(SubscriptionRepository);
  manageSubscriptionRepository = inject(ManageSubscriptionRepository);

  faAnglesDown = faAnglesDown;

  ngOnInit(): void {
    this.currentUserStore
      .getTestVersionInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.featureStore.getFeatures();
          this.loadCustomer();
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCustomer() {
    this.store.setLoading();

    this.featureStore
      .getFeatures()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.subscriptionRepository
            .getCurrentCustomer()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (customer) => {
                if (customer.subscriptionCanceled) {
                  this.store.cancelSubscription(customer.periodEndsAt!);
                } else {
                  this.store.reset();
                }
                this.store.stopLoading();

                this.store.setCustomerId(customer.id);
                this.checkCanChangeSubscription();
              },
              error: (error) => {
                this.store.stopLoading();
              },
            });
        },
        error: (error) => {
          this.store.stopLoading();
        },
      });
  }

  checkCanChangeSubscription(): void {
    this.store.setLoading();

    this.manageSubscriptionRepository
      .canChangeSubscription(this.store.customerId()!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (canChange) => {
          this.store.setCanChangeSubscription(canChange);
          this.store.stopLoading();

          if (!canChange) {
            this.getDowngradeInformation();
          }
        },
        error: (error) => {
          this.store.stopLoading();
        },
      });
  }

  getDowngradeInformation(): void {
    this.store.setLoading();

    this.manageSubscriptionRepository
      .getDowngradeInformation(this.store.customerId()!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (downgradeInformation) => {
          this.store.setDowngradeInformation(downgradeInformation);
          this.store.stopLoading();
        },
        error: (error) => {
          this.store.stopLoading();
        },
      });
  }

  toggleCancelDialog(): void {
    this.store.toggleCancelDialog();
  }

  cancelSubscription(): void {
    this.store.setLoading();
    this.manageSubscriptionRepository
      .cancelSubscription()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (date) => {
          this.toggleCancelDialog();
          this.store.cancelSubscription(date);
          this.store.stopLoading();
        },
        error: (error) => {
          this.store.toggleCancelDialog();
          this.store.stopLoading();
          console.error(error);
        },
      });
  }
}
