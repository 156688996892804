import { Component, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitchStore } from '../language-switch/language-switch.store';
import { LogoComponent } from '../logo/logo.component';

@Component({
    selector: 'app-imprint',
    imports: [TranslateModule, FontAwesomeModule, LogoComponent],
    templateUrl: './imprint.component.html',
    styleUrl: './imprint.component.scss'
})
export class ImprintComponent {
  languageStore = inject(LanguageSwitchStore);
}
