<section class="form-container">
  <app-form
    [titleKey]="'unauthenticated.register.customer-form.title'"
    [fields]="fields"
    [resetOnCancel]="false"
    [resetOnSubmit]="false"
    [cancelTextKey]="'unauthenticated.register.customer-form.back'"
    [submitTextKey]="this.registerStore.addCustomerParam()?.tenantId ? 'unauthenticated.register.customer-form.next' : 'unauthenticated.register.customer-form.to-authentication'"
    [submitClass]="'btn-secondary'"
    [cancelClass]="'btn-dark'"
    (submit)="onFormSubmit($event)"
    (cancel)="onCancel()"
  />
</section>
