import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-user-information',
    imports: [TranslateModule],
    templateUrl: './user-information.component.html',
    styleUrl: './user-information.component.scss'
})
export class UserInformationComponent {
  maxUsers = input<number>(0);
  activeUsers = input<number>(0);
}
