import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpService } from '../shared/http.service';
import { PrivateProjectCreateModel } from './add-project/private-project-create.model';
import { ProjectCreateModel } from './add-project/project-create.model';
import { ProjectModel } from './project.model';

const apiConfig = environment.apiConfig;

@Injectable({
  providedIn: 'root'
})
export class ProjectRepository {
  constructor(private readonly httpService: HttpService) {}

  getOurProjects(): Observable<ProjectModel[]> {
    return this.httpService.get<ProjectModel[]>(`${apiConfig.url}/project/user-has-access`);
  }

  getMyProjects(privateIncluded: boolean): Observable<ProjectModel[]> {
    return this.httpService.get<ProjectModel[]>(`${apiConfig.url}/project/my-projects?privateIncluded=${privateIncluded}`);
  }

  getProjects(workgroupId: string): Observable<ProjectModel[]> {
    return this.httpService.get<ProjectModel[]>(`${apiConfig.url}/project/for-workgroup/${workgroupId}`);
  }

  addProjectToWorkgroup(param: ProjectCreateModel): Observable<void> {
    return this.httpService.post<void>(`${apiConfig.url}/project/for-workgroup/create`, param);
  }

  addPrivateProject(param: PrivateProjectCreateModel): Observable<void> {
    return this.httpService.post<void>(`${apiConfig.url}/project/private/create`, param);
  }

  addProject(param: ProjectCreateModel): Observable<void> {
    return this.httpService.post<void>(`${apiConfig.url}/project/create`, param);
  }

  deleteProject(id: string): Observable<void> {
    return this.httpService.delete<void>(`${apiConfig.url}/project/${id}`);
  }
}
