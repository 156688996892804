import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LanguageSwitchStore } from '../language-switch/language-switch.store';

@Component({
    selector: 'app-terms-of-service',
    imports: [RouterModule],
    templateUrl: './terms-of-service.component.html',
    styleUrl: './terms-of-service.component.scss'
})
export class TermsOfServiceComponent {
  languageStore = inject(LanguageSwitchStore);
}
