import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormField } from '../../../shared/form/form-field';
import { FormComponent } from '../../../shared/form/form.component';
import { RegisterStore } from '../register.store';

@Component({
    selector: 'app-data-input',
    imports: [TranslateModule, FormComponent],
    templateUrl: './data-input.component.html',
    styleUrl: './data-input.component.scss'
})
export class DataInputComponent {
  registerStore = inject(RegisterStore);

  fields: FormField[] = [
    {
      fieldType: 'text',
      labelKey: 'unauthenticated.register.customer-form.name',
      name: 'name',
      required: true,
      defaultValue: this.registerStore.addCustomerParam()?.name,
    },
    {
      fieldType: 'email',
      labelKey: 'unauthenticated.register.customer-form.adminEmail',
      name: 'adminEmail',
      required: true,
      validators: [Validators.minLength(1), Validators.required],
      defaultValue: this.registerStore.addCustomerParam()?.adminEmail,
    },
    {
      fieldType: 'number',
      labelKey: 'unauthenticated.register.customer-form.givenUserCount',
      name: 'givenUserCount',
      required: !this.registerStore.testVersionSelected(),
      defaultValue: this.registerStore.testVersionSelected() ? 3 : this.registerStore.addCustomerParam()?.givenUserCount,
      minValue: 1,
      maxValue: this.registerStore.maxAmountOfUsersForSku()!,
      validators: [Validators.min(1), Validators.required, Validators.max(this.registerStore.maxAmountOfUsersForSku()!)],
      disabled: this.registerStore.testVersionSelected() || this.registerStore.selectedSku() === 0,
    },
  ];

  onFormSubmit($event: FormGroup) {
    const sku = this.registerStore.selectedSku();
    const adminEmail = $event.value.adminEmail || $event.value.representativeEmail;
    this.registerStore.setCustomerParam({
      ...$event.value,
      sku,
      adminEmail,
    });

    if (this.registerStore.addCustomerParam()?.tenantId) {
      this.registerStore.proceedToConfirmationStep();
    } else {
      this.registerStore.proceedToAuthenticateStep();
    }
  }

  onCancel() {
    this.registerStore.goBack();
  }
}
