@if (diagramType() && diagram()) {
<div class="diagram-header">
  <a class="back-button" (click)="onBack()">
    <fa-icon [icon]="faArrowLeft"></fa-icon>
    {{ "generic.back" | translate }}
  </a>
  <h1>
    {{ diagram()!.name }}
  </h1>
  @if (loading()) {
  <app-loading-spinner [minimal]="true"></app-loading-spinner>
  } @else {
  <app-last-edited [diagram]="diagram()"></app-last-edited>
  @if (currentUserStore.id() === diagram()!.ownerId || currentUserStore.isElevatedUser()) {
  <button
    [class]="diagram()!.archived ? 'btn-primary' : 'btn-warning'"
    (click)="onArchiveRequest()"
  >
    {{
      (diagram()!.archived ? "diagrams.unarchive" : "diagrams.archive")
        | translate
    }}
  </button>
  @if (diagram()!.archived) {
  <button class="btn-danger" (click)="onDeleteRequest()">
    {{ "diagrams.delete" | translate }}
  </button>
  } } }
</div>
@switch(diagramType()) { @case('priority') {
<app-priority [diagram]="diagram()" (changed)="onDiagramChanged($event)" />
} @case('lean-canvas') {
<app-lean-canvas [diagram]="diagram()" (changed)="onDiagramChanged($event)" />
} @case ('kanban') {
<app-kanban [diagram]="diagram()" (changed)="onDiagramChanged($event)" />
} @case ('risk-matrix') {
<app-risk-matrix [diagram]="diagram()" (changed)="onDiagramChanged($event)" />
} }
<app-dialog
  [dialogTitle]="'diagrams.archive' | translate"
  [showDialog]="showArchiveDialog()"
>
  <p>{{ "diagrams.archive-hint" | translate }}</p>
  <div class="btn-group">
    <button class="btn-secondary" (click)="archiveDiagram()">
      {{ "diagrams.archive" | translate }}
    </button>
    <button class="btn-light" (click)="cancelArchivation()">
      {{ "generic.cancel" | translate }}
    </button>
  </div>
</app-dialog>
<app-dialog
  [dialogTitle]="'diagrams.delete' | translate"
  [showDialog]="showDeleteDialog()"
>
  <p>{{ "diagrams.delete-hint" | translate }}</p>
  <div class="btn-group">
    <button class="btn-secondary" (click)="deleteDiagram()">
      {{ "diagrams.delete" | translate }}
    </button>
    <button class="btn-light" (click)="cancelDelete()">
      {{ "generic.cancel" | translate }}
    </button>
  </div>
</app-dialog>
}
