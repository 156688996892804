import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DataTableColumn } from '../../shared/data-table/data-table-column';
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { TestVersionLegendComponent } from '../../shared/test-version-legend/test-version-legend.component';
import { AddProjectComponent } from '../add-project/add-project.component';
import { ProjectModel } from '../project.model';
import { ProjectRepository } from '../project.repository';

@Component({
    selector: 'app-my-projects',
    imports: [
        TranslateModule,
        LoadingSpinnerComponent,
        FontAwesomeModule,
        AddProjectComponent,
        FormsModule,
        DataTableComponent,
        TestVersionLegendComponent
    ],
    templateUrl: './my-projects.component.html',
    styleUrl: './my-projects.component.scss'
})
export class MyProjectsComponent implements OnInit, OnDestroy {
  loadingProjects: boolean = false;
  showAddProjectDialog: boolean = false;
  $destroy = new Subject<void>();
  faPlus = faPlus;
  includePrivate = true;

  constructor(
    private readonly projectRepository: ProjectRepository,
    private readonly router: Router
  ) {}

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  ngOnInit(): void {
    this.loadProjects();
  }

  columns: DataTableColumn[] = [
    {
      prop: 'name',
      name: 'Name',
      type: 'text',
      translateKey: 'projects.table.name',
    },
    {
      prop: 'owner',
      name: 'Owner',
      type: 'text',
      translateKey: 'projects.table.owner',
    },
    {
      prop: 'createdAt',
      name: 'Created at',
      type: 'datetime',
      translateKey: 'projects.table.createdAt',
    },
    {
      prop: 'updatedAt',
      name: 'Updated at',
      type: 'date',
      translateKey: 'projects.table.updatedAt',
    },
  ];
  rows: any[] = [];

  onDetailClick = (row: ProjectModel) => {
    this.router.navigate(['/user/dashboard/' + row.id]);
  };

  loadProjects() {
    this.loadingProjects = true;
    this.projectRepository
      .getMyProjects(this.includePrivate)
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: (projects) => {
          this.rows = projects.map((project) => {
            return {
              id: project.id,
              name: project.name,
              owner: project.owner,
              createdAt: project.createdAt,
              updatedAt: project.updatedAt,
            };
          });
          this.loadingProjects = false;
        },
        error: (error) => {
          this.loadingProjects = false;
        },
      });
  }

  onCheckedChange() {
    this.includePrivate != this.includePrivate;
    this.loadProjects();
  }

  toggleAddDialog() {
    this.showAddProjectDialog = !this.showAddProjectDialog;
  }

  onAddProjectButtonClick() {
    this.toggleAddDialog();
  }

  onProjectAdded() {
    this.showAddProjectDialog = false;
    this.loadProjects();
  }
}
