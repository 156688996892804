import { inject, Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { FeatureStore } from '../shared/features/feature.store';
import { CurrentUserStore } from './current-user/current-user.store';

const apiConfig = environment.apiConfig;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  currentUserStore = inject(CurrentUserStore);
  featureStore = inject(FeatureStore);

  public get loggedIn(): boolean {
    return this._msalService.instance.getActiveAccount() != null;
  }

  destroy$ = new Subject<void>();

  public get role(): string {
    return this.currentUserStore.role() ?? '';
  }

  public get username(): string {
    return (
      this._msalService.instance.getActiveAccount()?.name ??
      this._msalService.instance.getActiveAccount()?.username ??
      ''
    );
  }

  public get tenantId(): string {
    return this._msalService.instance.getActiveAccount()?.tenantId ?? '';
  }

  constructor(
    private readonly _msalService: MsalService,
    private readonly _msalBroadcastService: MsalBroadcastService
  ) {
    this._msalService.initialize().subscribe();

    this._msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage | any) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.LOGIN_FAILURE
        )
      )
      .subscribe({
        next: (event) => {
          const allAccounts = this._msalService.instance.getAllAccounts();
          if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            allAccounts?.length > 0
          ) {
            this._msalService.instance.setActiveAccount(allAccounts[0]);
            this.currentUserStore.loginAndSetUserInfo();
          } else {
            if (
              event.eventType === EventType.LOGIN_SUCCESS &&
              event.payload.account
            ) {
              if (!event.payload) return;
              const account = event.payload.account!;
              this._msalService.instance.setActiveAccount(account);
              this.currentUserStore
                .loginAndSetUserInfo()
                .pipe(takeUntil(this.destroy$))
                .subscribe({ next: () => {} });
            }
          }
        },
        error: (err) => console.error(err),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public login(): void {
    this._msalService
      .initialize()
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !this.loggedIn)
      )
      .subscribe(() => {
        this._msalService.loginRedirect();
      });
  }

  public async logout() {
    this.currentUserStore.logout();
    this.clearCustomerActive();
    this._msalService.logoutRedirect().subscribe({
      error: (error) => {
        console.error(error);
      },
    });
  }

  private clearCustomerActive(): void {
    localStorage.removeItem('active');
  }
}
