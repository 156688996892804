@if (!store.answered()) {
<dialog id="cookieDialog" open>
  <div class="cookie-consent">
    <div class="cookie-consent__content">
      <h1>{{ "cookie-consent.tracking" | translate }}</h1>
      <p>{{ "cookie-consent.text" | translate }}</p>
      <a routerLink="/privacy">{{ 'userMenu.privacy' | translate }}</a>
    </div>
    <section class="btn-group">
      <button class="btn btn-secondary" (click)="enableTracking()">
        {{ "cookie-consent.accept" | translate }}
      </button>
      <button class="btn btn-dark" (click)="disableTracking()">
        {{ "cookie-consent.decline" | translate }}
      </button>
    </section>
  </div>
</dialog>
}
