import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  signal
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLock, faSearch } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, Subject, takeUntil } from 'rxjs';
import { MultiselectStore } from './multiselect.store';

@Component({
    selector: 'app-multiselect',
    imports: [FontAwesomeModule, FormsModule, CommonModule, TranslateModule],
    templateUrl: './multiselect.component.html',
    styleUrl: './multiselect.component.scss'
})
export class MultiselectComponent implements OnInit, OnDestroy {
  readonly store = inject(MultiselectStore);

  selectionItems = input<any[] | undefined | null>([]);
  selectedItems = input<any[] | undefined | null>([]);

  displayProp = input<string>('');
  searchProp = input<string>('');
  @Input() placeholder = 'generic.multiselect.placeholder';

  @Output() selectedItemsChanged = new EventEmitter<any[]>();

  protected searchTerm?: string;
  protected showDropdown = signal<boolean>(false);

  destroy$ = new Subject<void>();
  search$ = new BehaviorSubject<string | undefined>(undefined);

  faSearch = faSearch;
  faLock = faLock;

  constructor(private readonly renderer: Renderer2) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!(e.target as HTMLElement).closest('.multiselect-container')) {
        this.showDropdown.set(false);
      }
    });
  }

  ngOnInit(): void {
    this.store.setAvailableItems(this.selectionItems() ?? []);
    this.store.setSelectedItems(this.selectedItems() ?? []);
    this.store.setSearchProp(this.searchProp());
    this.store.setDisplayProp(this.displayProp());

    this.search$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe({
      next: (searchTerm: string | undefined) => {
        this.store.searchItem(searchTerm);
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickContainer(): void {
    this.showDropdown.set(!this.showDropdown());
  }

  onSearch(): void {
    this.search$.next(this.searchTerm);
  }

  onSelectItem(item: any): void {
    this.store.setSelectedItems([...this.store.selectedItems(), item]);
    this.selectedItemsChanged.emit(this.store.selectedItems());
  }

  onRemoveItem($event: MouseEvent, item: any): void {
    if (!item.locked) {
      $event.stopPropagation();
      this.store.removeItem(item);
      this.search$.next(this.searchTerm);
      this.selectedItemsChanged.emit(this.store.selectedItems());
    }
  }
}
