<h1 class="mb-40">{{ "tweak-subscription.title" | translate }}</h1>
<div class="flex-column-start-start gap-60">
  <section class="flex-row-center-spacearound gap-20 wrap width-100">
    @for (sku of skuModels(); track $index) {
    <app-sku-item
      [sku]="sku"
      (click)="selectSku(sku)"
      [class.selected]="store.selectedSku()?.sku === sku.sku"
    />
    }
  </section>
  <section
    class="width-100"
    [class.flex-row-center-spacebetween]="!store.loading()"
    [class.flex-row-start-center]="store.loading()"
    [class.gap-40]="store.loading()"
  >
    <div>
      <label
        >{{ "tweak-subscription.amount-of-users" | translate
        }}<span class="req">*</span></label
      >
      <input
        type="number"
        required
        [value]="store.currentAmountOfUsers()"
        [value]="store.selectedAmountOfUsers()"
        min="1"
        [max]="store.selectedSku()?.maxAmountOfUsers ?? 1"
        (input)="onUserAmountChanged($event)"
      />
    </div>
    @if (store.loading()) {
    <app-loading-spinner [minimal]="true" />
    } @else { @if (store.preview() && store.preview()?.eligible) {
    <div>
      <label>{{ "tweak-subscription.price" | translate }}</label>
      <span class="bold price">{{
        store.preview()?.totalExcludingTax | currency : "EUR"
      }}</span>
    </div>
    } @else if (store.preview() && !store.preview()?.eligible) {
    <p style="max-width: 350px">
      {{ "tweak-subscription.downgrade-hint" | translate }}
    </p>
    <div>
      <label>{{
        "tweak-subscription.price-next-billing-period" | translate
      }}</label>
      <span class="bold price">{{
        store.expectedCost() | currency : "EUR"
      }}</span>
    </div>
    } }
  </section>
  <section class="flex-row-center-spacebetween gap-20">
    <p class="net-hint">{{ "tweak-subscription.net-hint" | translate }}</p>
    <button class="btn-primary" (click)="openConfirmChangesDialog()">
      {{ "tweak-subscription.save" | translate }}
    </button>
  </section>
</div>
<app-dialog [showDialog]="showConfirmDialog()">
  <h2>{{ "tweak-subscription.confirm-title" | translate }}</h2>
  <p>{{ "tweak-subscription.confirm-text" | translate }}</p>
  <div class="flex-row-center-spacearound gap-20">
    <button (click)="confirm()" class="btn-secondary">
      {{ "tweak-subscription.confirm" | translate }}
    </button>
    <button (click)="cancel()" class="btn-dark">
      {{ "tweak-subscription.cancel" | translate }}
    </button>
  </div>
</app-dialog>
