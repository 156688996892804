<section appFeatureDisplay [minimumFeatureSku]="1" class="selector-wrapper">
  <h2>{{ "projects.selector.title" | translate }}</h2>
  <select
    [disabled]="loading"
    [(ngModel)]="selectedProjectId"
    (change)="onSelect($event)"
  >
    <option value="private">
      {{ "projects.selector.private" | translate }}
    </option>
    @for(project of projects; track project.id) {
    <option [value]="project.id">
      {{ project.name }} ({{ project.diagramCount }})
    </option>
    }
  </select>
  @if (featureStore.customerSku() >= 1) {
  <button class="btn-success" (click)="onCreate()">
    {{ "projects.selector.create" | translate }}
  </button>
  <app-add-project
  [showDialog]="showCreateProjectDialog()"
  (projectAdded)="onAdded()"
  (canceled)="onCanceled()"
  (loading)="onLoading()"
  [private]="false"
></app-add-project>
  }
  <app-test-version-legend [sku]="1" [height]="28"></app-test-version-legend>
  @if (loading) {
  <app-loading-spinner [minimal]="true" />
  }
</section>
