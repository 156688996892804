<h2>{{ "downgrade-information.title" | translate }}</h2>
<section class="downgrade-information">
  <div class="current">
    <h2>{{ "downgrade-information.current-title" | translate }}</h2>
    <span
      class="sub"
      [class.basic]="downgradeInformation()?.currentSku === 0"
      [class.startup]="downgradeInformation()?.currentSku === 1"
      [class.pro]="downgradeInformation()?.currentSku === 2">
    @switch (downgradeInformation()?.currentSku) {
      @case(0) { BASIC }
      @case(1) { STARTUP }
      @case(2) { PRO }
      @default { ? }
    }
    </span>
      <h3>{{ "downgrade-information.current-users" | translate }}</h3>
      <p>{{ downgradeInformation()?.currentAmountOfUsers }}</p>
  </div>
  <div class="transition">
    <fa-icon size="3x" [icon]="faForward" />
    <p>{{ "downgrade-information.change-date" | translate }} {{ downgradeInformation()?.currentPeriodEndsUtc | date: 'dd.MM.yyyy' }}</p>
  </div>
  <div class="next">
    <h2>{{ "downgrade-information.next-title" | translate }}</h2>
    <span
      class="sub"
      [class.basic]="downgradeInformation()?.newSku === 0"
      [class.startup]="downgradeInformation()?.newSku === 1"
      [class.pro]="downgradeInformation()?.newSku === 2">
    @switch (downgradeInformation()?.newSku) {
      @case(0) { BASIC }
      @case(1) { STARTUP }
      @case(2) { PRO }
      @default { ? }
    }
    </span>
      <h3>{{ "downgrade-information.next-users" | translate }}</h3>
      <p>{{ downgradeInformation()?.newAmountOfUsers }}</p>
  </div>
</section>
